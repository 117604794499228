"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productSelectorCreatorByColorAndSku = exports.makeGetAvailableColorsMemoizeSelectorCreator = exports.customSelectedSkuDeliveryPromiseSelectorCreator = exports.customSelectedSkuSelectorCreator = exports.customSelectedColorSelectorCreator = exports.productSelectorCreatorByActiveSizedForSelectedColor = exports.productSelectorCreatorBySelectedColorId = exports.productSelectorCreatorByProductCode = void 0;
const reselect_1 = require("reselect");
const common_utils_1 = require("@as-react/common-utils");
const GeneralUtil_1 = require("../../../util/GeneralUtil");
const SelectorUtil_1 = require("./SelectorUtil");
// eslint-disable-next-line @typescript-eslint/ban-types
const defaultMemoize = reselect_1.defaultMemoize;
const productCodeIsEqual = (currentProduct, previousProduct) => currentProduct.productCode === previousProduct.productCode;
const productSelectedColorIdIsEqual = (currentProduct, previousProduct) => currentProduct.selectedColorId === previousProduct.selectedColorId;
const productSelectedSkuIsEqual = (currentProduct, previousProduct) => currentProduct.selectedSku === previousProduct.selectedSku;
const productColorAndSkuAreEqual = (currentProduct, previousProduct) => currentProduct.selectedColorId === previousProduct.selectedColorId &&
    currentProduct.selectedSku === previousProduct.selectedSku;
const selectedSkuDeliveryPromiseIsDifferent = (currentProduct, previousProduct) => currentProduct.deliveryPromise === previousProduct.deliveryPromise;
const productColorAndPriceAreEqual = (currentProduct, previousProduct) => {
    if (currentProduct.selectedColorId !== previousProduct.selectedColorId ||
        currentProduct.selectedSku !== previousProduct.selectedSku) {
        return false;
    }
    const currentColorVariation = SelectorUtil_1.default.getSelectedColor(currentProduct);
    const previousColorVariation = SelectorUtil_1.default.getSelectedColor(previousProduct);
    if ((0, common_utils_1.isNullOrUndefined)(currentColorVariation) || (0, common_utils_1.isNullOrUndefined)(previousColorVariation)) {
        return false;
    }
    if (currentProduct.selectedSku) {
        const currentColorVariationSKu = SelectorUtil_1.default.getSelectedSku(currentProduct, currentColorVariation);
        const previousColorVariationSKu = SelectorUtil_1.default.getSelectedSku(previousProduct, currentColorVariation);
        return (0, GeneralUtil_1.isEqual)(currentColorVariationSKu.prices, previousColorVariationSKu.prices);
    }
    return (0, GeneralUtil_1.isEqual)(currentColorVariation.prices, previousColorVariation.prices);
};
const productActiveSizesForSelectedColor = (currentProduct, previousProduct) => {
    if (currentProduct.selectedColorId !== previousProduct.selectedColorId) {
        return false;
    }
    const currentColorVariation = SelectorUtil_1.default.getSelectedColor(currentProduct);
    const previousColorVariation = SelectorUtil_1.default.getSelectedColor(previousProduct);
    if ((0, common_utils_1.isNullOrUndefined)(currentColorVariation) || (0, common_utils_1.isNullOrUndefined)(previousColorVariation)) {
        return false;
    }
    const activeCurrentSizes = currentColorVariation.sizes
        .filter(currentSize => currentSize.active)
        .map(currentSize => currentSize.code);
    const activePreviousSizes = previousColorVariation.sizes
        .filter(previousSize => previousSize.active)
        .map(previousSize => previousSize.code);
    return (0, GeneralUtil_1.isEqual)(activeCurrentSizes, activePreviousSizes);
};
const productContainsSameColorsInSameActiveAndVisibleState = (currentProduct, previousProduct) => {
    const currentProductVariation = currentProduct.productColorVariations;
    const previousProductVariation = previousProduct.productColorVariations;
    if (currentProductVariation.length !== previousProductVariation.length ||
        (0, common_utils_1.isNullOrUndefined)(currentProductVariation) ||
        (0, common_utils_1.isNullOrUndefined)(previousProductVariation)) {
        return false;
    }
    const currentPossibleColors = currentProductVariation.map(currentColorVariation => ({
        colorId: currentColorVariation.colorId,
        active: currentColorVariation.active,
        visible: currentColorVariation.visible,
        isAvailable: Number(currentColorVariation.availability) > 0,
    }));
    const previousPossibleColors = previousProductVariation.map(previousColorVariation => ({
        colorId: previousColorVariation.colorId,
        active: previousColorVariation.active,
        visible: previousColorVariation.visible,
        isAvailable: Number(previousColorVariation.availability) > 0,
    }));
    return (0, GeneralUtil_1.isEqual)(currentPossibleColors, previousPossibleColors);
};
exports.productSelectorCreatorByProductCode = (0, reselect_1.createSelectorCreator)(defaultMemoize, productCodeIsEqual);
exports.productSelectorCreatorBySelectedColorId = (0, reselect_1.createSelectorCreator)(defaultMemoize, productColorAndPriceAreEqual);
exports.productSelectorCreatorByActiveSizedForSelectedColor = (0, reselect_1.createSelectorCreator)(defaultMemoize, productActiveSizesForSelectedColor);
exports.customSelectedColorSelectorCreator = (0, reselect_1.createSelectorCreator)(defaultMemoize, productSelectedColorIdIsEqual);
exports.customSelectedSkuSelectorCreator = (0, reselect_1.createSelectorCreator)(defaultMemoize, productSelectedSkuIsEqual);
exports.customSelectedSkuDeliveryPromiseSelectorCreator = (0, reselect_1.createSelectorCreator)(defaultMemoize, selectedSkuDeliveryPromiseIsDifferent);
exports.makeGetAvailableColorsMemoizeSelectorCreator = (0, reselect_1.createSelectorCreator)(defaultMemoize, productContainsSameColorsInSameActiveAndVisibleState);
exports.productSelectorCreatorByColorAndSku = (0, reselect_1.createSelectorCreator)(defaultMemoize, productColorAndSkuAreEqual);
