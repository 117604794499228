"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationPopover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ForgotPassword_1 = require("./ForgotPassword");
const Login_1 = require("./Login");
const AuthenticationPopover = ({ facebook, forgotPassword, login, anchorEl, onClose, registrationLink, socialLogin, onAuthResetForm, forgotPasswordActive, toggleView, cookieConsentSettingAvailable, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const [email, setEmail] = (0, react_1.useState)('');
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { dataQA: "login_popover", size: cotopaxi_1.PopoverSize.LARGE, onClose: onClose, anchorEl: anchorEl, hasScrollLock: !isDesktop, title: forgotPasswordActive ? t('account.login.title.password.forgotten') : t('account.login.title.login'), rightElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: onClose, icon: {
                name: cotopaxi_1.IconName.CLOSE,
                color: cotopaxi_1.IconColor.ACTION,
                size: cotopaxi_1.IconSize.MAJOR,
            } }), leftElement: forgotPasswordActive ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: toggleView, noBubble: true, icon: {
                name: cotopaxi_1.IconName.ARROW_LEFT,
                color: cotopaxi_1.IconColor.ACTION,
                size: cotopaxi_1.IconSize.MAJOR,
            } })) : null, children: forgotPasswordActive ? ((0, jsx_runtime_1.jsx)(ForgotPassword_1.ForgotPassword, Object.assign({}, forgotPassword, { initialEmail: email, backToLoginClicked: toggleView, onEmailChange: setEmail }))) : ((0, jsx_runtime_1.jsx)(Login_1.Login, { login: Object.assign(Object.assign({}, login), { forgotPasswordClicked: toggleView, initialEmail: email }), facebook: facebook, registrationLink: registrationLink, socialLogin: socialLogin, onAuthResetForm: onAuthResetForm, onEmailChange: setEmail, cookieConsentSettingAvailable: cookieConsentSettingAvailable })) }));
};
exports.AuthenticationPopover = AuthenticationPopover;
