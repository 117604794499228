"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Authentication = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ForgotPassword_1 = require("./ForgotPassword");
const Login_1 = require("./Login");
const Authentication = ({ facebook, forgotPassword, login, anchorEl, onClose, registrationLink, socialLogin, onAuthResetForm, cookieConsentSettingAvailable, inPopover, hideTitle = false, hasShadow = false, isInAuthorMode = false, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const email = (0, react_1.useRef)('');
    const [forgotPasswordActive, setForgotPasswordActive] = (0, react_1.useState)(false);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isClient = (0, cotopaxi_1.useIsClient)(isInAuthorMode);
    if (!isClient)
        return null;
    const toggleView = () => {
        setForgotPasswordActive(!forgotPasswordActive);
    };
    const handleEmailChange = (value) => {
        email.current = value;
    };
    const title = forgotPasswordActive ? t('account.login.title.password.forgotten') : t('account.login.title.login');
    const renderContent = forgotPasswordActive ? ((0, jsx_runtime_1.jsx)(ForgotPassword_1.ForgotPassword, Object.assign({}, forgotPassword, { initialEmail: email.current, backToLoginClicked: toggleView, onEmailChange: handleEmailChange }))) : ((0, jsx_runtime_1.jsx)(Login_1.Login, { login: Object.assign(Object.assign({}, login), { forgotPasswordClicked: toggleView, initialEmail: email.current }), facebook: facebook, registrationLink: registrationLink, socialLogin: socialLogin, onAuthResetForm: onAuthResetForm, onEmailChange: handleEmailChange, cookieConsentSettingAvailable: cookieConsentSettingAvailable }));
    return inPopover ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { dataQA: "login_popover", size: cotopaxi_1.PopoverSize.LARGE, onClose: onClose, anchorEl: anchorEl, hasScrollLock: !isDesktop, title: title, rightElement: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: onClose, icon: {
                name: cotopaxi_1.IconName.CLOSE,
                color: cotopaxi_1.IconColor.ACTION,
                size: cotopaxi_1.IconSize.MAJOR,
            } }), leftElement: forgotPasswordActive ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.TRANSPARENT, onClick: toggleView, noBubble: true, icon: {
                name: cotopaxi_1.IconName.ARROW_LEFT,
                color: cotopaxi_1.IconColor.ACTION,
                size: cotopaxi_1.IconSize.MAJOR,
            } })) : null, children: renderContent })) : ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { withShadow: !isMobile || hasShadow, mobileBleed: true, mobile: {
            spacing: {
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 3,
                paddingBottom: 1,
            },
            theme: cotopaxi_1.BoxTheme.PRIMARY,
        }, tablet: {
            spacing: {
                paddingLeft: 4,
                paddingRight: 4,
                paddingTop: 2,
                paddingBottom: 3,
            },
        }, desktop: {
            spacing: {
                paddingLeft: 6,
                paddingRight: 6,
                paddingTop: 5,
                paddingBottom: 4,
            },
        }, children: [!hideTitle && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, desktop: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: title }) })), renderContent] }));
};
exports.Authentication = Authentication;
