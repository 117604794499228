"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const AuthenticationPopover_1 = require("../components/account/authentication/AuthenticationPopover");
const AuthActions_creators_1 = require("../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../redux/authentication/selectors/AuthSelectors");
const CookieConsentSelector_1 = require("../redux/cookieConsent/selectors/CookieConsentSelector");
const SessionActions_creators_1 = require("../redux/session/actions/SessionActions.creators");
const _AuthenticationContainer = ({ initSocial, login, reset, actionUri, loginError, loading, resetPasswordState, anchorEl, onClose, registrationLink, socialLogin, onAuthResetForm, cookieConsentSettingAvailable, resetCredentialsSuccessAction, }) => {
    (0, react_1.useEffect)(() => {
        initSocial();
    }, []);
    const [forgotPasswordActive, setForgotPasswordActive] = (0, react_1.useState)(false);
    const toggleView = () => {
        setForgotPasswordActive(prev => !prev);
        resetCredentialsSuccessAction(null);
    };
    const handleLoginSubmit = (values) => login({
        username: values.email,
        password: values.password,
        keepSignedIn: values.keepSignedIn,
        reloadOnSuccess: true,
        location: common_types_1.AuthFormLocation.POPOVER,
    });
    const handleClose = () => {
        onClose();
        setTimeout(() => setForgotPasswordActive(false), cotopaxi_1.POPOVER_ANIMATION_DURATION);
    };
    const handleResetPasswordSubmit = (values) => reset(values.email);
    return ((0, jsx_runtime_1.jsx)(AuthenticationPopover_1.AuthenticationPopover, { login: {
            action: actionUri,
            onSubmit: handleLoginSubmit,
            errorFeedback: loginError,
            loading: loading,
        }, forgotPassword: {
            onSubmit: handleResetPasswordSubmit,
            errorFeedback: resetPasswordState.error,
            successFeedback: resetPasswordState.success,
        }, facebook: {}, anchorEl: anchorEl, onClose: handleClose, registrationLink: registrationLink, socialLogin: socialLogin, onAuthResetForm: onAuthResetForm, forgotPasswordActive: forgotPasswordActive, toggleView: toggleView, cookieConsentSettingAvailable: cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE }));
};
const makeMapStateToProps = () => {
    const getLoginError = (0, AuthSelectors_1.makeGetLoginError)();
    const getResetPasswordState = (0, AuthSelectors_1.makeGetResetPasswordState)();
    const getLoginLoading = (0, AuthSelectors_1.makeGetLoginLoading)();
    const getCookieConsentSettingAvailable = (0, CookieConsentSelector_1.makeGetCookieConsentSettingAvailable)();
    return state => ({
        loginError: getLoginError(state),
        loading: getLoginLoading(state),
        resetPasswordState: getResetPasswordState(state),
        cookieConsentSettingAvailable: getCookieConsentSettingAvailable(state),
    });
};
const mapDispatchToProps = {
    login: AuthActions_creators_1.authActions.login,
    logout: SessionActions_creators_1.default.createLogoutAction,
    reset: AuthActions_creators_1.authActions.createResetCredentialsAction,
    initSocial: AuthActions_creators_1.authActions.createInitSocialAction,
    socialLogin: AuthActions_creators_1.authActions.createSocialLoginAction,
    onAuthResetForm: AuthActions_creators_1.authActions.resetError,
    resetCredentialsSuccessAction: AuthActions_creators_1.authActions.createResetCredentialsSuccessAction,
};
exports.AuthenticationContainer = (0, react_redux_1.connect)(makeMapStateToProps, mapDispatchToProps)(_AuthenticationContainer);
