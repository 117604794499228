"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const Filter_1 = require("../../components/lister/filters/Filter");
const FilterActions_creators_1 = require("../../redux/filter/actions/FilterActions.creators");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const UrlActions_creators_1 = require("../../redux/url/actions/UrlActions.creators");
const ListerActions_creators_1 = require("../../redux/lister/actions/ListerActions.creators");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const FilterContainer = ({ showInStoreFilter, isBrandPage = false }) => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const onFilterItemChange = (toggledFilter) => dispatch(FilterActions_creators_1.filterActions.updateFilters(toggledFilter));
    const removeAllFilters = () => dispatch(FilterActions_creators_1.filterActions.removeAllFilters());
    const updateInStoreFilter = (inStoreFilter) => dispatch(ListerActions_creators_1.listerActions.updateInStoreFilter(inStoreFilter));
    const itemCount = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetItemCount)());
    const filters = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetEnabledFiltersState)(isBrandPage));
    const filterWizardConfig = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetFilterWizardState)());
    const activeFilters = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetActiveFiltersState)());
    const fredhopperGlossary = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetfredhopperGlossaryState)());
    const urlFilters = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetUrlFilters)());
    const inStoreFilter = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetInStoreFilterState)());
    const activeStoreId = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetStoreId)());
    const openInStoreFilterPopup = () => {
        dispatch(BasketActions_creators_1.basketActions.createCCPopupOpenAction());
        if (!inStoreFilter) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.storeFilterStarted());
        }
        else {
            dispatch(AnalyticsActions_creators_1.analyticsActions.storeFilterModified());
        }
    };
    const updateStoreId = (storeId) => {
        dispatch(UrlActions_creators_1.urlActions.updateStoreId(storeId));
        if (!activeStoreId) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.storeFilterApplied());
        }
    };
    return ((0, jsx_runtime_1.jsx)(Filter_1.Filter, { filters: filters, activeFilters: activeFilters, itemCount: itemCount, fredhopperGlossary: fredhopperGlossary, filterWizardConfig: filterWizardConfig, removeAllFilters: removeAllFilters, onFilterItemChange: onFilterItemChange, urlFilters: urlFilters, storeOptions: {
            inStoreFilter,
            activeStoreId,
            showInStoreFilter,
            updateInStoreFilter,
            updateStoreId,
            openInStoreFilterPopup,
        } }));
};
exports.FilterContainer = FilterContainer;
