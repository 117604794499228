"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventEnum = void 0;
var EventEnum;
(function (EventEnum) {
    EventEnum["USER_LOGGED_IN"] = "userLoggedIn";
    EventEnum["USER_LOGGED_OUT"] = "userLoggedOut";
    EventEnum["LOGIN_SUCCESS"] = "loginSuccess";
    EventEnum["BASKET_SET"] = "basketSet";
    EventEnum["BASKET_CHANGED"] = "basketChanged";
    EventEnum["BASKET_PROMOTION_CALLED"] = "basketPromotionCalled";
    EventEnum["BASKET_POPUP_VISIBLE"] = "basketPopupVisible";
    EventEnum["SOCIAL_MEDIA_CONNECTION_ADDED"] = "socialMediaConnectionAdded";
    EventEnum["VOUCHER_TEMPLATE_CHANGED"] = "voucherTemplateChanged";
    EventEnum["VOUCHER_AMOUNT_CHANGED"] = "voucherAmountChanged";
    EventEnum["VOUCHER_MESSAGE_CHANGED"] = "voucherMessageChanged";
    EventEnum["WISHLIST_CHANGED"] = "wishlistChanged";
    EventEnum["DYNAMIC_IMPORT_SUCCESS"] = "dynamicImportSuccess";
    EventEnum["COOKIE_CONSENT_PREFERENCES"] = "cookieConsentPreferences";
    EventEnum["COOKIE_CONSENT_PREFERENCES_UPDATED"] = "cookieConsentPreferencesUpdated";
    EventEnum["COOKIE_CONSENT_PREFERENCES_SAVED"] = "cookieConsentPreferencesSaved";
    EventEnum["NEW_VIDEO_IFRAME_ADDED"] = "newVideoIframeAdded";
    EventEnum["VOUCHER_CHANGED"] = "voucherChanged";
    EventEnum["USER_SECURITY_LEVEL_TOO_LOW"] = "userSecurityLevelTooLowTrigger";
    EventEnum["LATITUDE_USER_LOGGED_IN"] = "latitudeUserLoggedIn";
    EventEnum["MONETATE_SCRIPT_LOADED"] = "monetateScriptLoaded";
    EventEnum["MONETATE_RECOMMENDATIONS"] = "monetateRecommendations";
    EventEnum["SIZE_GUIDE_POPOVER_OPEN"] = "sizeGuidePopoverOpen";
    EventEnum["APPLE_PAY_PAYMENT_FAILED"] = "applePayPaymentFailed";
    EventEnum["MOBILE_NAVIGATION_CLICK"] = "mobileNavigationClick";
})(EventEnum || (exports.EventEnum = EventEnum = {}));
