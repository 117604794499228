"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrencyISOCode = exports.convertToCurrencySymbol = exports.CurrencySymbolsEnum = exports.IsoCurrencyEnum = void 0;
var IsoCurrencyEnum;
(function (IsoCurrencyEnum) {
    IsoCurrencyEnum["EUR"] = "EUR";
    IsoCurrencyEnum["AUD"] = "AUD";
    IsoCurrencyEnum["GBP"] = "GBP";
})(IsoCurrencyEnum || (exports.IsoCurrencyEnum = IsoCurrencyEnum = {}));
var CurrencySymbolsEnum;
(function (CurrencySymbolsEnum) {
    CurrencySymbolsEnum["EUR"] = "\u20AC";
    CurrencySymbolsEnum["AUD"] = "$";
    CurrencySymbolsEnum["GBP"] = "\u00A3";
})(CurrencySymbolsEnum || (exports.CurrencySymbolsEnum = CurrencySymbolsEnum = {}));
const convertToCurrencySymbol = (symb) => {
    switch (symb) {
        case '&pound;':
        case '&#163;':
            return '£';
        case '&dollar;':
        case '&#36;':
            return '$';
        case '&euro;':
        case '&#128;':
            return '€';
        default:
            return '';
    }
};
exports.convertToCurrencySymbol = convertToCurrencySymbol;
const getCurrencyISOCode = (currency) => {
    switch (currency) {
        case CurrencySymbolsEnum.AUD:
            return IsoCurrencyEnum.AUD;
        case CurrencySymbolsEnum.GBP:
            return IsoCurrencyEnum.GBP;
        case CurrencySymbolsEnum.EUR:
        default:
            return IsoCurrencyEnum.EUR;
    }
};
exports.getCurrencyISOCode = getCurrencyISOCode;
