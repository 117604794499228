"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpertReviews = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const ExpertReview_1 = require("./ExpertReview");
const ExpertReviews = ({ expertReviews }) => {
    if (expertReviews.length === 0) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { column: true, gutter: cotopaxi_1.GroupGutter.LARGE, children: expertReviews.map((review, index) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [index > 0 && (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}), (0, jsx_runtime_1.jsx)(ExpertReview_1.ExpertReview, { expertReview: review }, review.id)] }))) }));
};
exports.ExpertReviews = ExpertReviews;
