"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const FilterItemContentBucketFilter_1 = require("./FilterItemContentBucketFilter");
const FilterItemContentColorFilter_1 = require("./FilterItemContentColorFilter");
const FilterItemContentMultiSelect_1 = require("./FilterItemContentMultiSelect");
const FilterItemContentRangeFilter_1 = require("./FilterItemContentRangeFilter");
const FilterItemContentLinkFilter_1 = require("./FilterItemContentLinkFilter");
const FilterItemTitle_1 = require("./FilterItemTitle");
const FilterUtil_1 = require("../../../util/FilterUtil");
const react_redux_1 = require("react-redux");
const ListerSelector_1 = require("../../../redux/lister/selectors/ListerSelector");
var TestMatchOptions;
(function (TestMatchOptions) {
    TestMatchOptions["EXACT"] = "exact";
    TestMatchOptions["REGEX"] = "regex";
})(TestMatchOptions || (TestMatchOptions = {}));
const dynamicFilterSelectors = [
    {
        test: [
            {
                key: 'type',
                value: common_types_1.MULTISELECT_FILTER,
                matching: TestMatchOptions.EXACT,
            },
            {
                key: 'filterOn',
                value: '^(?!.*colour).*$',
                matching: TestMatchOptions.REGEX,
            },
        ],
        component: FilterItemContentMultiSelect_1.FilterItemContentMultiSelect,
    },
    {
        test: [
            {
                key: 'type',
                value: common_types_1.MULTISELECT_FILTER,
                matching: TestMatchOptions.EXACT,
            },
            {
                key: 'filterOn',
                value: /^(.*colour).*$/g,
                matching: TestMatchOptions.REGEX,
            },
        ],
        component: FilterItemContentColorFilter_1.FilterItemContentColorFilter,
    },
    {
        test: [
            {
                key: 'type',
                value: common_types_1.RANGE_FILTER,
                matching: TestMatchOptions.EXACT,
            },
        ],
        component: FilterItemContentRangeFilter_1.FilterItemContentRangeFilter,
    },
    {
        test: [
            {
                key: 'type',
                value: common_types_1.BUCKET_FILTER,
                matching: TestMatchOptions.EXACT,
            },
        ],
        component: FilterItemContentBucketFilter_1.FilterItemContentBucketFilter,
    },
    {
        test: [
            {
                key: 'type',
                value: common_types_1.LINK_FILTER,
                matching: TestMatchOptions.EXACT,
            },
        ],
        component: FilterItemContentLinkFilter_1.FilterItemContentLinkFilter,
    },
];
const dynamicFilterTesterFunction = (dynamicComponentTester, filterObj) => {
    switch (dynamicComponentTester.matching) {
        case TestMatchOptions.REGEX:
            return new RegExp(dynamicComponentTester.value).exec(String(filterObj[dynamicComponentTester.key])) !== null;
        case TestMatchOptions.EXACT:
            return filterObj[dynamicComponentTester.key] === dynamicComponentTester.value;
        default:
            return false;
    }
};
const dynamicFilterFunction = (dynamicComponentChecker, filterObj) => dynamicComponentChecker.test.every(tester => dynamicFilterTesterFunction(tester, filterObj));
const FilterItem = ({ desktop, filter, isActive, onFilterItemChange, isCollapsedByDefault, filterWizardConfig, tooltip, totalItemsCount, urlFilters, }) => {
    var _a;
    const activeFilters = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetActiveFiltersState)());
    const DynamicFilter = dynamicFilterSelectors.find(dynamicFilterSelector => dynamicFilterFunction(dynamicFilterSelector, filter));
    if (DynamicFilter) {
        const DynamicComponent = DynamicFilter.component;
        const showFilterWizard = !!urlFilters && ((_a = filterWizardConfig === null || filterWizardConfig === void 0 ? void 0 : filterWizardConfig.filters) === null || _a === void 0 ? void 0 : _a.includes(filter.filterOn)) && filter.type !== common_types_1.RANGE_FILTER;
        return desktop ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Collapse, { id: `filter-item-collapse-${filter.name.replace(/ /g, '_').toLowerCase()}`, dataQA: filter.name.replace(/ /g, '_').toLowerCase(), open: !isCollapsedByDefault, iconSize: cotopaxi_1.IconSize.MEDIUM, labelGutterVertical: cotopaxi_1.LabelGutter.SMALL, type: cotopaxi_1.CollapseType.FILTER_ITEM, title: (0, jsx_runtime_1.jsx)(FilterItemTitle_1.FilterItemTitle, { tooltip: !!tooltip && !showFilterWizard && (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: tooltip }), badge: (0, FilterUtil_1.getSelectedFilterAmount)(filter.filterOn, activeFilters), title: filter.name, showFilterWizard: showFilterWizard, filter: filter, filterWizardConfig: filterWizardConfig, updateFilters: onFilterItemChange, urlFilters: urlFilters, initialItemsCount: totalItemsCount }), children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(DynamicComponent, { toggleFilterOptions: filter.toggleFilterOptions, desktop: desktop, filter: filter, onFilterItemChange: onFilterItemChange, isCollapsedByDefault: Boolean(isCollapsedByDefault), isActive: isActive }) }) })) : ((0, jsx_runtime_1.jsx)(DynamicComponent, { toggleFilterOptions: false, desktop: desktop, filter: filter, onFilterItemChange: onFilterItemChange, isCollapsedByDefault: Boolean(isCollapsedByDefault), isActive: isActive }));
    }
    return null;
};
exports.FilterItem = FilterItem;
