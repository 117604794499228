"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListerDividerContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_redux_1 = require("react-redux");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const redux_1 = require("redux");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const _ListerDividerContainer = ({ listerHeadingInformation }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Divider, { type: cotopaxi_1.DividerType.HORIZONTAL, dataQA: "search_result_product-count", margin: cotopaxi_1.DividerMargin.MEDIUM, children: [listerHeadingInformation.itemCount, " ", t('product.products').toLowerCase()] }));
};
const makeMapStateToProps = () => {
    const getListerHeadingInformation = (0, ListerSelector_1.makeGetListerHeadingInformation)();
    const mapStateToProps = (state) => ({
        listerHeadingInformation: getListerHeadingInformation(state),
    });
    return mapStateToProps;
};
const composer = (0, redux_1.compose)((0, react_redux_1.connect)(makeMapStateToProps));
exports.ListerDividerContainer = composer(_ListerDividerContainer);
