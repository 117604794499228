"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockAvailabilityPopup = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const AvailabilityInCurrentStoreTable_1 = require("./AvailabilityInCurrentStoreTable");
const AvailabilityInAllStoresTable_1 = require("./AvailabilityInAllStoresTable");
const StockAvailabilityPopup = ({ productCode, productSizes, currentStoreData = [], allStoresData, selectedSku, isOneSizeProduct, productBrand, productTitle, productImageCdnDomains, fetchProductAvailabilityForAllStores, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.store]);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { dataQA: "check_stock", size: cotopaxi_1.ButtonSize.SMALL, variant: cotopaxi_1.ButtonVariant.LINK, iconLeft: { name: cotopaxi_1.IconName.T_SHIRT_SOLID }, text: t('store.show.available.stock'), onClick: handlePopoverOpen }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Popover, { type: cotopaxi_1.PopoverType.MODAL, anchorEl: anchorEl, title: t('store.check.stock.popup.title'), onClose: handlePopoverClose, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                            spacing: {
                                paddingRight: 4,
                                paddingLeft: 4,
                                paddingBottom: 4,
                            },
                        }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 4 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { dataQA: "check_stock_title", size: cotopaxi_1.TextSize.SMALL, inline: true, children: [productTitle, " - ", productBrand, " - ", productCode] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, emphasized: true, children: t('store.check.stock.this.store') }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(AvailabilityInCurrentStoreTable_1.AvailabilityInCurrentStoreTable, { productCode: productCode, productSizes: productSizes, productColors: currentStoreData, selectedSku: selectedSku, isOneSizeProduct: isOneSizeProduct, productImageCdnDomains: productImageCdnDomains, onCellClick: fetchProductAvailabilityForAllStores }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {})] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, children: t('store.select.size.txt') })] }), (allStoresData === null || allStoresData === void 0 ? void 0 : allStoresData.length) > 0 && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                            spacing: {
                                marginTop: 1.5,
                                paddingRight: 4,
                                paddingLeft: 4,
                                paddingBottom: 4,
                                paddingTop: 3,
                            },
                            theme: cotopaxi_1.BoxTheme.PRIMARY,
                        }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                    spacing: {
                                        paddingBottom: 3,
                                    },
                                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, emphasized: true, children: t('store.check.stock.all.stores') }) }), (0, jsx_runtime_1.jsx)(AvailabilityInAllStoresTable_1.AvailabilityInAllStoresTable, { allStoresData: allStoresData })] }))] })] }));
};
exports.StockAvailabilityPopup = StockAvailabilityPopup;
