"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const StoreItem_1 = require("../store-item/StoreItem");
const StoreUtil_1 = require("../../../../util/StoreUtil");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const StoreList = ({ stores, defaultRequestParams, storeFinderType, disabledAddToBasket, fasciaFirstMessage, onStoreClick, storeSourceExcluded, sortBy, specialtyType, location, isLocationBlocked, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.store]);
    const getBoxParams = (isMessage) => ({
        mobile: {
            spacing: {
                paddingTop: isMessage ? 2 : 0,
                paddingBottom: isMessage ? 2 : 0,
                paddingLeft: 2,
                paddingRight: 2,
                marginBottom: isMessage ? 2 : 0,
            },
            theme: isMessage ? cotopaxi_1.BoxTheme.TERTIARY : undefined,
        },
        tablet: {
            spacing: {
                paddingTop: isMessage ? 1 : 0,
                paddingBottom: isMessage ? 1 : 0,
                paddingLeft: 3,
                paddingRight: 3,
            },
        },
    });
    if (fasciaFirstMessage) {
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [stores.length > 0 && Boolean(stores[0].storeId) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, getBoxParams(), { children: (0, jsx_runtime_1.jsx)(StoreItem_1.default, { store: stores[0], storeFinderType: storeFinderType, disabledAddToBasket: disabledAddToBasket, onStoreClick: onStoreClick, fasciaFirstMessage: fasciaFirstMessage, storeSourceExcluded: storeSourceExcluded, sortBy: sortBy, specialtyType: specialtyType, location: location, isLocationBlocked: isLocationBlocked }, stores[0].storeId) }))), stores.length > 1 && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, getBoxParams(true), { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: "B500", type: cotopaxi_1.TextType.CONTENT, children: StoreUtil_1.default.getStoreOwnFasciaTranslation(defaultRequestParams.market, defaultRequestParams.mainWebshop, t) }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, getBoxParams(), { children: stores.slice(1).map((store, index) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [index !== 0 && (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { margin: cotopaxi_1.DividerMargin.XLARGE }), (0, jsx_runtime_1.jsx)(StoreItem_1.default, { store: store, storeFinderType: storeFinderType, disabledAddToBasket: disabledAddToBasket, onStoreClick: onStoreClick, storeSourceExcluded: storeSourceExcluded, sortBy: sortBy, specialtyType: specialtyType, location: location, isLocationBlocked: isLocationBlocked })] }, store.storeId))) }))] }))] }));
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, getBoxParams(), { children: stores.map((store, index) => ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [index !== 0 && (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { margin: cotopaxi_1.DividerMargin.XLARGE }), (0, jsx_runtime_1.jsx)(StoreItem_1.default, { store: store, storeFinderType: storeFinderType, disabledAddToBasket: disabledAddToBasket, onStoreClick: onStoreClick, storeSourceExcluded: storeSourceExcluded, sortBy: sortBy, specialtyType: specialtyType, location: location, isLocationBlocked: isLocationBlocked }, store.storeId)] }, store.storeId))) })));
};
exports.StoreList = StoreList;
