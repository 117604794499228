"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersMobileCollapsible = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const FiltersMobileItemHeader_1 = require("./FiltersMobileItemHeader");
const cotopaxi_1 = require("@as/cotopaxi");
const FilterItem_1 = require("../FilterItem");
const FiltersMobileCollapsible = ({ filter, itemCount, onFilterItemChange, fredhopperGlossary, filterWizardConfig, urlFilters, }) => {
    var _a;
    const { iconClasses, contentProps, toggleProps } = (0, andes_react_1.useCollapsible)();
    const tooltip = fredhopperGlossary && ((_a = fredhopperGlossary[`fh_${filter.filterOn}`]) === null || _a === void 0 ? void 0 : _a.description);
    contentProps.overflow = 'auto';
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(andes_react_1.CollapsibleToggle, Object.assign({}, toggleProps, { tag: "div", children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(FiltersMobileItemHeader_1.FiltersMobileItemHeader, { filter: filter, itemCount: itemCount, tooltip: tooltip, urlFilters: urlFilters, filterWizardConfig: filterWizardConfig }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.CENTER, className: iconClasses, children: (0, jsx_runtime_1.jsx)(andes_react_1.Icon, { name: "chevron-down" }) })] }) })), (0, jsx_runtime_1.jsx)(andes_react_1.CollapsibleContent, Object.assign({}, contentProps, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(FilterItem_1.FilterItem, { filter: filter, onFilterItemChange: onFilterItemChange, filterWizardConfig: filterWizardConfig, urlFilters: urlFilters }) }) }))] }));
};
exports.FiltersMobileCollapsible = FiltersMobileCollapsible;
