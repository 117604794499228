"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const AddToBasketButton_1 = require("../add-to-basket-button/AddToBasketButton");
const StoreFinderButton_1 = require("../store-finder-button/StoreFinderButton");
const AddToWishlistButton_1 = require("../add-to-wishlist-button/AddToWishlistButton");
const SizeSelectV2_1 = require("../size-select-v2/SizeSelectV2");
const ActionEnums_1 = require("../../../enums/ActionEnums");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const NotifyMeForm_1 = require("../../notify-me/NotifyMeForm");
const StickyBar = ({ isHidden, isMobile, selectedSku, productColorIsInWishList, wishListProduct, isOneSizeProduct, sizes, basketValidationState, sizeRecommendation, shopLandingEnabledFlag, clickAndCollectEnabled, hdExcluded, addToBasket, openCC, sizeForgotten, toggleWishList, selectSize, setBasketError, productPriceProps, imageUrl, detailInformation: { brand: { description }, title, }, storeSourceExcluded, isSelectedSizeSoldOut, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const configuration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(configuration);
    const notifyMeButtonIcon = {
        name: cotopaxi_1.IconName.EMAIL,
        size: cotopaxi_1.IconSize.MAJOR,
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.StickyBar, { isHidden: isHidden, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Container, { type: cotopaxi_1.ContainerType.FLUID, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [!isMobile && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { size: cotopaxi_1.ImageSize.DPE_LOGO_TITLE, src: imageUrl }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: {
                                        spacing: {
                                            marginLeft: 2,
                                            marginRight: 2,
                                        },
                                    }, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { isClipped: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, inline: true, children: description }), ' ', title] }), productPriceProps && (0, jsx_runtime_1.jsx)(cotopaxi_1.ProductPrice, Object.assign({ size: cotopaxi_1.PriceSize.MEDIUM }, productPriceProps))] })] }) })), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: isDesktop ? cotopaxi_1.GroupGutter.MEDIUM : cotopaxi_1.GroupGutter.SMALL, inline: isOneSizeProduct, children: [(0, jsx_runtime_1.jsx)(SizeSelectV2_1.SizeSelectV2, { id: "sticky_bar_size_select", basketValidationState: basketValidationState, sizes: sizes, selectedSku: selectedSku, sizeRecommendation: sizeRecommendation, isOneSizeProduct: isOneSizeProduct, isStickyBar: true, isHiddenStickyBar: isHidden, selectSize: selectSize, setBasketError: setBasketError, isSelectedSizeSoldOut: isSelectedSizeSoldOut }), isSelectedSizeSoldOut && !isShoplanding ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: isOneSizeProduct, onClick: () => {
                                    ScrollUtil_1.ScrollUtil.scrollToElement(document.querySelector(`form[name="${NotifyMeForm_1.NOTIFY_ME_FORM_NAME}"]`), true, 'center');
                                }, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, text: (isDesktop || isOneSizeProduct) && t('notifyMe.submit.button'), icon: !isDesktop && !isOneSizeProduct ? notifyMeButtonIcon : undefined, iconLeft: isDesktop || isOneSizeProduct ? notifyMeButtonIcon : undefined })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!hdExcluded && !storeSourceExcluded && ((0, jsx_runtime_1.jsx)(AddToBasketButton_1.AddToBasketButton, { disabled: !productPriceProps, onClick: (quantity) => addToBasket(quantity, ActionEnums_1.ProductAddToBasketLocations.STICKY_BAR, common_types_1.PDPVersion.V2), isOneSizeProduct: isOneSizeProduct, isStickyBar: true })), (hdExcluded || storeSourceExcluded) && clickAndCollectEnabled && ((0, jsx_runtime_1.jsx)(StoreFinderButton_1.default, { text: t('stickyBar.storeFinder'), selectedSku: selectedSku, isStickyBar: true, openCC: openCC, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, sizeForgotten: () => sizeForgotten(ActionEnums_1.ProductAddToBasketLocations.STICKY_BAR) })), !shopLandingEnabledFlag && ((0, jsx_runtime_1.jsx)(AddToWishlistButton_1.default, { isProductInWishList: productColorIsInWishList, onToggle: toggleWishList, wishListProduct: wishListProduct, isStickyBar: true }))] }))] })] }) }) }));
};
exports.default = StickyBar;
