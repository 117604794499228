"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const StoreAPI_1 = require("../../api/store/StoreAPI");
const BuyConfirmationAccount_1 = require("../../components/buy/confirmation/BuyConfirmationAccount");
const BuyConfirmationCart_1 = require("../../components/buy/confirmation/BuyConfirmationCart");
const BuyConfirmationDeliveryMethodInfo_1 = require("../../components/buy/confirmation/BuyConfirmationDeliveryMethodInfo");
const BuyConfirmationErrorPage_1 = require("../../components/buy/confirmation/BuyConfirmationErrorPage");
const BuyConfirmationEVoucherDeliveryInfo_1 = require("../../components/buy/confirmation/BuyConfirmationEVoucherDeliveryInfo");
const BuyConfirmationOrder_1 = require("../../components/buy/confirmation/BuyConfirmationOrder");
const BuyConfirmationSupportInfo_1 = require("../../components/buy/confirmation/BuyConfirmationSupportInfo");
const NewsletterSubscription_1 = require("../../components/buy/confirmation/NewsletterSubscription");
const BuyConfirmationSizebay_1 = require("../../components/sizebay/BuyConfirmationSizebay");
const i18nConstants_1 = require("../../constants/i18nConstants");
const StorageKeysConstants_1 = require("../../constants/StorageKeysConstants");
const AnalyticsEnums_1 = require("../../enums/AnalyticsEnums");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const AuthActions_creators_1 = require("../../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../../redux/authentication/selectors/AuthSelectors");
const CheckoutActions_creators_1 = require("../../redux/checkout/actions/CheckoutActions.creators");
const CheckoutSelector_1 = require("../../redux/checkout/selectors/CheckoutSelector");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const LocalStorageUtil_1 = require("../../util/LocalStorageUtil");
const URLParamUtil_1 = require("../../util/URLParamUtil");
const BuyConfirmationContainer = ({ deliveryInfo, newsletter, orderInfo, support, usp, voucher, }) => {
    var _a;
    const PAYMENT_STATUS_IN_WRONG_STATUS = 'in_wrong_status';
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout]);
    const [storeDetails, setStoreDetails] = (0, react_1.useState)();
    const [isPaymentInWrongStatus, setPaymentInWrongStatus] = (0, react_1.useState)(false);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const businessUnitId = (0, ConfigurationUtil_1.getBusinessUnitId)(aemConfiguration);
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const confirmationError = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetConfirmationError)());
    const order = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetConfirmationOrder)());
    const resetPasswordState = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetResetPasswordState)());
    const loginError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginError)());
    const newsletterPreferences = (0, react_redux_1.useSelector)((0, CheckoutSelector_1.makeGetNewsletterPreferences)());
    const isLoggedInByThirdParty = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInByThirdPartyState)());
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const sessionState = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const getOrder = () => dispatch(CheckoutActions_creators_1.default.createGetConfirmationOrderAction());
    const socialLogin = (provider) => dispatch(AuthActions_creators_1.authActions.createSocialLoginAction(provider));
    const setPassword = (email) => dispatch(AuthActions_creators_1.authActions.createResetCredentialsAction(email));
    const showCreateAccount = () => {
        var _a;
        return newsletterPreferences &&
            !newsletterPreferences.hasPassword &&
            !newsletterPreferences.hasSocialMediaAccountLinked &&
            ((_a = Object.keys(usp)) === null || _a === void 0 ? void 0 : _a.filter(key => key !== 'successMessage' && key !== 'sendMessage').length) > 0;
    };
    const defaultNewsletterSubscription = () => newsletterPreferences && (newsletterPreferences.newCustomer || newsletterPreferences.currentEmailOptin);
    const showNewsletterCheckbox = () => newsletterPreferences && (newsletterPreferences.newCustomer || !newsletterPreferences.currentEmailOptin);
    const getConfirmationTitle = () => isShoplanding
        ? t('buy:buy.confirmation.shop.pay.at.checkout', {
            orderId: order === null || order === void 0 ? void 0 : order.orderNumber,
        })
        : t('checkout:checkout.confirmation.title.loggedin', {
            firstName: order === null || order === void 0 ? void 0 : order.firstName,
        });
    (0, react_1.useEffect)(() => {
        getOrder();
        LocalStorageUtil_1.default.removeItem(StorageKeysConstants_1.CHOSEN_PAYMENT_OPTION);
        setPaymentInWrongStatus((0, URLParamUtil_1.getQuerystringByName)(window.location.search, 'status') === PAYMENT_STATUS_IN_WRONG_STATUS);
    }, []);
    (0, react_1.useEffect)(() => {
        if (sessionState.authorities) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.checkoutType(isLoggedIn ? AnalyticsEnums_1.CheckoutType.LOGGED_IN : AnalyticsEnums_1.CheckoutType.GUEST));
        }
    }, [sessionState]);
    (0, react_1.useEffect)(() => {
        var _a;
        const getStoreInformation = (storeId) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            try {
                const store = yield StoreAPI_1.default.getStoreInformation(servicesEndpoint, defaultRequestParameters, businessUnitId, storeId);
                setStoreDetails(store);
            }
            catch (_b) { }
        });
        if ((_a = order === null || order === void 0 ? void 0 : order.deliveryAddressResource) === null || _a === void 0 ? void 0 : _a.storeId) {
            getStoreInformation(order.deliveryAddressResource.storeId);
        }
    }, [order]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2 } }, desktop: { spacing: { marginTop: 3, marginBottom: 5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.CheckoutLayout, { children: [confirmationError && (0, jsx_runtime_1.jsx)(BuyConfirmationErrorPage_1.BuyConfirmationErrorPage, { retry: getOrder }), !!order && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.CheckoutLayoutItem, { variant: cotopaxi_1.CheckoutLayoutItemVariants.BUY_CONFIRMATION_CART, order: 2, children: (0, jsx_runtime_1.jsx)(BuyConfirmationCart_1.BuyConfirmationCart, { order: order }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.CheckoutLayoutItem, { order: 1, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, desktop: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, priority: cotopaxi_1.HeadingPriority.H1, children: getConfirmationTitle() }) }), isPaymentInWrongStatus && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: `${t('buy:buy.confirmation.payment.inprogress')}`, iconSize: cotopaxi_1.IconSize.MAJOR, variant: cotopaxi_1.FeedbackMessageVariant.INFO, id: "buy-confirmation__payment-pending" }) })), isShoplanding ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 5 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { variant: cotopaxi_1.FeedbackMessageVariant.INFO, iconSize: cotopaxi_1.IconSize.MAJOR, text: `${t('buy:buy.confirmation.shop.order.info.message')}: ${order.contactPerson}` }), order.requiresDelivery && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.List, { icon: {
                                                    name: cotopaxi_1.IconName.CHECK,
                                                    color: cotopaxi_1.IconColor.POSITIVE,
                                                    size: cotopaxi_1.IconSize.MAJOR,
                                                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.List.Item, { children: (0, jsx_runtime_1.jsx)(BuyConfirmationDeliveryMethodInfo_1.BuyConfirmationDeliveryMethodInfo, { deliveryInfo: deliveryInfo, order: order, store: storeDetails, isShoplanding: true }) }) }) })), showNewsletterCheckbox() && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginLeft: 5 } }, children: (0, jsx_runtime_1.jsx)(NewsletterSubscription_1.NewsletterSubscription, { order: order, newsletter: newsletter, defaultNewsletterSubscription: defaultNewsletterSubscription() }) }))] })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 5 } }, tablet: { spacing: { marginBottom: 6 } }, desktop: { spacing: { marginBottom: 7 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.List, { icon: {
                                            name: cotopaxi_1.IconName.CHECK,
                                            color: cotopaxi_1.IconColor.POSITIVE,
                                            size: cotopaxi_1.IconSize.MAJOR,
                                        }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.List.Item, { children: (0, jsx_runtime_1.jsx)(BuyConfirmationOrder_1.BuyConfirmationOrder, { order: order, orderInfo: orderInfo, newsletter: newsletter, defaultNewsletterSubscription: defaultNewsletterSubscription(), showNewsletterCheckbox: showNewsletterCheckbox() }) }), order.hasEvoucher && ((0, jsx_runtime_1.jsx)(cotopaxi_1.List.Item, { children: (0, jsx_runtime_1.jsx)(BuyConfirmationEVoucherDeliveryInfo_1.BuyConfirmationEVoucherDeliveryInfo, { contactPerson: order.contactPerson, digitalDeliveryInfo: voucher.digitalDeliveryInfo }) })), order.requiresDelivery && ((0, jsx_runtime_1.jsx)(cotopaxi_1.List.Item, { children: (0, jsx_runtime_1.jsx)(BuyConfirmationDeliveryMethodInfo_1.BuyConfirmationDeliveryMethodInfo, { deliveryInfo: deliveryInfo, order: order, store: storeDetails }) })), ((_a = Object.keys(support)) === null || _a === void 0 ? void 0 : _a.length) > 0 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.List.Item, { children: (0, jsx_runtime_1.jsx)(BuyConfirmationSupportInfo_1.BuyConfirmationSupportInfo, { support: support }) }))] }) })), showCreateAccount() && ((0, jsx_runtime_1.jsx)(BuyConfirmationAccount_1.BuyConfirmationAccount, { order: order, usp: usp, isSocialLoginEnabled: !isShoplanding, socialLogin: socialLogin, setPassword: setPassword, resetPasswordState: resetPasswordState, loginError: loginError, isLoggedInByThirdParty: isLoggedInByThirdParty }))] }), (0, jsx_runtime_1.jsx)(BuyConfirmationSizebay_1.BuyConfirmationSizebay, { order: order })] }))] }) }));
};
exports.default = BuyConfirmationContainer;
