"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLoggedInGuestWebReturns = exports.isLoggedInByThirdParty = exports.isLoggedInUser = void 0;
const common_types_1 = require("@as-react/common-types");
const isLoggedInUser = (userSession) => {
    var _a;
    return ((_a = userSession === null || userSession === void 0 ? void 0 : userSession.authorities) === null || _a === void 0 ? void 0 : _a.length)
        ? userSession.authorities[0] && userSession.authorities[0].authority !== common_types_1.SessionAuthorityTypes.ANONYMOUS
        : false;
};
exports.isLoggedInUser = isLoggedInUser;
const isLoggedInByThirdParty = (userSession) => {
    var _a, _b;
    return ((_a = userSession === null || userSession === void 0 ? void 0 : userSession.authorities) === null || _a === void 0 ? void 0 : _a.length)
        ? ((_b = userSession.authorities[1]) === null || _b === void 0 ? void 0 : _b.authority) === common_types_1.SessionAuthorityTypes.THIRD_PARTY
        : false;
};
exports.isLoggedInByThirdParty = isLoggedInByThirdParty;
const isLoggedInGuestWebReturns = (userSession) => {
    var _a, _b, _c;
    return ((_a = userSession === null || userSession === void 0 ? void 0 : userSession.authorities) === null || _a === void 0 ? void 0 : _a.length)
        ? ((_b = userSession.authorities[0]) === null || _b === void 0 ? void 0 : _b.authority) === common_types_1.SessionAuthorityTypes.ANONYMOUS &&
            ((_c = userSession.authorities[1]) === null || _c === void 0 ? void 0 : _c.authority) === common_types_1.SessionAuthorityTypes.GUEST_WEB_RETURNS
        : false;
};
exports.isLoggedInGuestWebReturns = isLoggedInGuestWebReturns;
