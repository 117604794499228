"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ZendeskWidgetUtil {
    static updateSettings(settings) {
        if (this.isEnabled() && this.isV1Widget()) {
            window[this.zendeskInterfaceName]('webWidget', 'updateSettings', {
                webWidget: settings,
            });
        }
    }
    static resetZIndex() {
        if (this.isEnabled() && this.isV1Widget()) {
            window[this.zendeskInterfaceName]('webWidget', 'updateSettings', {
                webWidget: {
                    zIndex: this.defaultZIndex,
                },
            });
        }
    }
    static isEnabled() {
        return !!window[this.zendeskInterfaceName];
    }
    static isV1Widget() {
        return 'show' in window[this.zendeskInterfaceName];
    }
}
ZendeskWidgetUtil.zendeskInterfaceName = 'zE';
ZendeskWidgetUtil.defaultZIndex = '899';
exports.default = ZendeskWidgetUtil;
