"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileNavigationLoginLevel = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const Login_1 = require("../../account/authentication/Login");
const AuthActions_creators_1 = require("../../../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../../../redux/authentication/selectors/AuthSelectors");
const ForgotPassword_1 = require("../../account/authentication/ForgotPassword");
const CookieConsentSelector_1 = require("../../../redux/cookieConsent/selectors/CookieConsentSelector");
const MobileNavigationItemContent_1 = require("./MobileNavigationItemContent");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const GeneralConstants_1 = require("../../../constants/GeneralConstants");
const MobileNavigationLoginLevel = ({ actionUri, registrationLink, isTabActive, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const [forgotPasswordActive, setForgotPasswordActive] = (0, react_1.useState)(false);
    const [email, setEmail] = (0, react_1.useState)('');
    const toggleView = () => setForgotPasswordActive(!forgotPasswordActive);
    const loading = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginLoading)());
    const cookieConsentSettingAvailable = (0, react_redux_1.useSelector)((0, CookieConsentSelector_1.makeGetCookieConsentSettingAvailable)());
    const resetPasswordState = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetResetPasswordState)());
    const loginError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginError)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const handleLoginSubmit = (values) => dispatch(AuthActions_creators_1.authActions.login({
        username: values.email,
        password: values.password,
        keepSignedIn: values.keepSignedIn,
        reloadOnSuccess: true,
    }));
    const handleResetPasswordSubmit = (values) => dispatch(AuthActions_creators_1.authActions.createResetCredentialsAction(values.email));
    (0, react_1.useEffect)(() => {
        forgotPasswordActive && setTimeout(() => setForgotPasswordActive(false), GeneralConstants_1.DELAY.DELAY_250); // reset state after menu animations completes
    }, [isTabActive]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(MobileNavigationItemContent_1.MobileNavigationItemContent, { emphasized: true, children: forgotPasswordActive ? t('account.login.title.password.forgotten') : t('account.login.title.login') }) }), forgotPasswordActive ? ((0, jsx_runtime_1.jsx)(ForgotPassword_1.ForgotPassword, { onSubmit: handleResetPasswordSubmit, errorFeedback: resetPasswordState.error, successFeedback: resetPasswordState.success, initialEmail: email, backToLoginClicked: toggleView, onEmailChange: setEmail })) : ((0, jsx_runtime_1.jsx)(Login_1.Login, { login: {
                    action: actionUri,
                    onSubmit: handleLoginSubmit,
                    errorFeedback: loginError,
                    forgotPasswordClicked: toggleView,
                    initialEmail: email,
                    loading,
                }, facebook: {}, registrationLink: registrationLink, socialLogin: payload => dispatch(AuthActions_creators_1.authActions.createSocialLoginAction(payload)), onAuthResetForm: () => dispatch(AuthActions_creators_1.authActions.resetError()), onEmailChange: setEmail, cookieConsentSettingAvailable: cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE }))] }));
};
exports.MobileNavigationLoginLevel = MobileNavigationLoginLevel;
