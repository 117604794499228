"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeGetUserEmail = exports.makeGetIsLoggedIn = exports.makeGetLoggedInByThirdPartyState = exports.makeGetLoggedInState = exports.makeGetUUID = exports.makeGetFirstName = exports.makeGetSessionState = void 0;
const reselect_1 = require("reselect");
const SessionUtil_1 = require("./../../../util/SessionUtil");
const sessionStateSelector = (state) => state.session || {};
const makeGetSessionState = () => (0, reselect_1.createSelector)(sessionStateSelector, (sessionState) => sessionState);
exports.makeGetSessionState = makeGetSessionState;
const makeGetFirstName = () => (0, reselect_1.createSelector)(sessionStateSelector, (sessionState) => sessionState.firstName);
exports.makeGetFirstName = makeGetFirstName;
const makeGetUUID = () => (0, reselect_1.createSelector)(sessionStateSelector, (sessionState) => sessionState.uuid);
exports.makeGetUUID = makeGetUUID;
const makeGetLoggedInState = () => (0, reselect_1.createSelector)(sessionStateSelector, (sessionState) => (0, SessionUtil_1.isLoggedInUser)(sessionState));
exports.makeGetLoggedInState = makeGetLoggedInState;
const makeGetLoggedInByThirdPartyState = () => (0, reselect_1.createSelector)(sessionStateSelector, (sessionState) => (0, SessionUtil_1.isLoggedInByThirdParty)(sessionState));
exports.makeGetLoggedInByThirdPartyState = makeGetLoggedInByThirdPartyState;
const makeGetIsLoggedIn = () => (0, reselect_1.createSelector)(sessionStateSelector, (sessionState) => sessionState.isLoggedIn);
exports.makeGetIsLoggedIn = makeGetIsLoggedIn;
const makeGetUserEmail = () => (0, reselect_1.createSelector)(sessionStateSelector, (sessionState) => sessionState.isLoggedIn ? sessionState.username : null);
exports.makeGetUserEmail = makeGetUserEmail;
