"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectedFilterAmount = exports.getBucketFilter = exports.getRangeFilter = exports.getMultiFilter = exports.getFilterQueryParameter = void 0;
const getFilterQueryParameter = (filterValue) => `&filter=${encodeURIComponent(filterValue)}`;
exports.getFilterQueryParameter = getFilterQueryParameter;
// Example: &filter=brand:(171_be;118_be)
const getMultiFilter = (filter, values) => (0, exports.getFilterQueryParameter)(`${filter}:(${values.join(';')})`);
exports.getMultiFilter = getMultiFilter;
// Example: &filter=product_colour_selling_price0902:[500;1000]
const getRangeFilter = (filter, min, max) => (0, exports.getFilterQueryParameter)(`${filter}:[${min};${max}]`);
exports.getRangeFilter = getRangeFilter;
// Example: &filter=company_v_in_web:102<company_v_in_web<103
const getBucketFilter = (filter, value) => (0, exports.getFilterQueryParameter)(`${filter}:${value}`);
exports.getBucketFilter = getBucketFilter;
const getSelectedFilterAmount = (filter, currentFilters) => {
    var _a;
    if (!currentFilters || !currentFilters[filter]) {
        return undefined;
    }
    const activeFilter = currentFilters[filter];
    return ((_a = activeFilter === null || activeFilter === void 0 ? void 0 : activeFilter.values) === null || _a === void 0 ? void 0 : _a.length) || 1;
};
exports.getSelectedFilterAmount = getSelectedFilterAmount;
