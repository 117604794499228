"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const ListerSelector_1 = require("../../redux/lister/selectors/ListerSelector");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const react_redux_1 = require("react-redux");
const InStoreFilterToast_1 = require("../../components/lister/filters/in-store-filter-toast/InStoreFilterToast");
const UrlActions_creators_1 = require("../../redux/url/actions/UrlActions.creators");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const InStoreFilterToastContainer = () => {
    const dispatch = (0, react_redux_1.useDispatch)();
    const inStoreFilter = (0, react_redux_1.useSelector)((0, ListerSelector_1.makeGetInStoreFilterState)());
    const activeStoreId = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetStoreId)());
    const handleUpdateStoreId = (storeId) => {
        dispatch(UrlActions_creators_1.urlActions.updateStoreId(storeId));
        if (!activeStoreId) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.storeFilterApplied());
        }
    };
    return ((0, jsx_runtime_1.jsx)(InStoreFilterToast_1.default, { inStoreFilter: inStoreFilter, activeStoreId: activeStoreId, updateStoreId: handleUpdateStoreId }));
};
exports.default = InStoreFilterToastContainer;
