"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FreeDeliveryMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const asNumeral_1 = require("../../../vendors/asNumeral");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const MESSAGE_THRESHOLD = '{THRESHOLD}';
const FreeDeliveryMessage = ({ freeDeliveryMessage, thresholdFreeDelivery, }) => {
    var _a, _b, _c;
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const currency = (0, ConfigurationUtil_1.getCurrency)(aemConfiguration);
    const pricing = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetPriceInformation)());
    if (!thresholdFreeDelivery || !freeDeliveryMessage)
        return null;
    const message = ((_b = (_a = pricing.prices) === null || _a === void 0 ? void 0 : _a.SELL) === null || _b === void 0 ? void 0 : _b.original) >= thresholdFreeDelivery
        ? freeDeliveryMessage.equalOrAboveThreshold
        : freeDeliveryMessage.belowThreshold;
    if (!message.productText)
        return null;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [message.showTruckIcon && ((0, jsx_runtime_1.jsx)(cotopaxi_1.GridItem, { row: {
                    start: 1,
                    end: 'span 2',
                }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 0.25, marginRight: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.TRUCK, color: message.fontColor === 'green' ? cotopaxi_1.IconColor.POSITIVE : cotopaxi_1.IconColor.DEFAULT, size: cotopaxi_1.IconSize.MINOR }) }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: message.fontColor === 'green' ? cotopaxi_1.TextColor.POSITIVE : cotopaxi_1.TextColor.INHERIT, size: cotopaxi_1.TextSize.SMALL, emphasized: true, children: (_c = message.productText) === null || _c === void 0 ? void 0 : _c.replace(MESSAGE_THRESHOLD, `${currency}${asNumeral_1.AsNumeral.getInstance().formatAsCurrency(thresholdFreeDelivery)}`) })] }));
};
exports.FreeDeliveryMessage = FreeDeliveryMessage;
