"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPassword = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const FormValidationUtil_1 = require("../../../util/FormValidationUtil");
const ForgotPasswordForm_1 = require("../forgot-password-form/ForgotPasswordForm");
const ForgotPassword = ({ action, onSubmit, errorFeedback, successFeedback, backToLoginClicked, initialEmail, onEmailChange, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.navigation, i18nConstants_1.I18N_NAMESPACES.general]);
    const handleValidation = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = t('account:delivery.required');
        }
        else if (!FormValidationUtil_1.default.isValidEmailPattern(values.email)) {
            errors.email = t('general:email.error.invalid');
        }
        return errors;
    };
    return ((0, jsx_runtime_1.jsxs)("div", { "data-analytics-bannerid": "header-account-password-reset", children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, gutterBottom: true, children: (0, jsx_runtime_1.jsx)(ForgotPasswordForm_1.ForgotPasswordForm, { onSubmit: onSubmit, action: action, errorFeedback: t(errorFeedback), successFeedback: t(successFeedback), validate: handleValidation, initialEmail: initialEmail, onEmailChange: onEmailChange }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { gutter: false, gutterBottom: true, gutterTop: true, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, emphasized: true, children: [t('navigation:flyout.account.password.remember'), ' '] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, noBubble: true, onClick: backToLoginClicked, text: t('account:account.login.title.login') })] }) })] }));
};
exports.ForgotPassword = ForgotPassword;
