"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const andes_react_1 = require("@yonderland/andes-react");
const ProductSpecialtyMessageContainer = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const specialtyType = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSpecialtyType)());
    if (!specialtyType) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 3, marginTop: 2, padding: 2 }, theme: cotopaxi_1.BoxTheme.TERTIARY }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { color: "B500", children: [t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.specialty.message`, {
                    specialty_type: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.specialty.type.${specialtyType}`),
                }), ' ', (0, jsx_runtime_1.jsx)(andes_react_1.Tooltip, { text: t(`${i18nConstants_1.I18N_NAMESPACES.product}:product.specialty.tooltip.${specialtyType}`), hasCloseBtn: false, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { size: cotopaxi_1.IconSize.MINOR, name: cotopaxi_1.IconName.INFORMATION_OUTLINE }) })] }) }));
};
exports.default = ProductSpecialtyMessageContainer;
