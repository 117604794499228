"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const applepayEndpoints = {
    validatemerchant: 'api/applepay/validatemerchant',
    checkout: 'api/applepay/checkout',
    updateShippingAddress: 'api/applepay/update/shippingaddress',
    payment: 'api/applepay/payment',
};
class ApplePayAPI {
    constructor() {
        this.validateMerchant = (servicesApi, validateMerchantData) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${applepayEndpoints.validatemerchant}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: validateMerchantData,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data.statusMessage;
        });
        this.initiateCheckout = (servicesApi, storeId) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${applepayEndpoints.checkout}`,
                params: { storeId },
                withCredentials: true,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.updateShippingAddress = (servicesApi, paymentContactData) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${applepayEndpoints.updateShippingAddress}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: paymentContactData,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
        this.payment = (servicesApi, paymentData) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const axiosConfig = {
                method: 'POST',
                url: `${servicesApi}${applepayEndpoints.payment}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: paymentData,
            };
            const result = yield axios_1.default.request(axiosConfig);
            return result.data;
        });
    }
}
exports.default = new ApplePayAPI();
