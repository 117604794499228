"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersMobileOld = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const FiltersMobileOldPopover_1 = require("./FiltersMobileOldPopover");
const FiltersMobileButton_1 = require("./FiltersMobileButton");
const FiltersMobileOld = ({ activeFilters, buttonText, filters, filterWizardConfig, fredhopperGlossary, isContentTileLister, itemCount, onFilterItemChange, removeAllFilters, storeOptions, urlFilters, }) => {
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(FiltersMobileButton_1.FiltersMobileButton, { text: buttonText, onClick: handlePopoverOpen, isContentTileLister: isContentTileLister }), (0, jsx_runtime_1.jsx)(FiltersMobileOldPopover_1.FiltersMobileOldPopover, { isContentTileLister: isContentTileLister, anchorEl: anchorEl, handlePopoverClose: handlePopoverClose, onFilterItemChange: onFilterItemChange, filters: filters, activeFilters: activeFilters, itemCount: itemCount, removeAllActiveFilters: removeAllFilters, fredhopperGlossary: fredhopperGlossary, filterWizardConfig: filterWizardConfig, urlFilters: urlFilters, storeOptions: storeOptions })] }));
};
exports.FiltersMobileOld = FiltersMobileOld;
