"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidPostCode = exports.isValidAddress = exports.getDefaultCountry = exports.buyFormHandleErrors = exports.showNamedDays = exports.showDeliveryDates = exports.filterDeliveryOptions = exports.chooseDeliveryOption = exports.isAllDaysOptions = exports.isNamedDayOrHomeDeliveryOptions = exports.isNextOrNamedDayOptions = exports.findUSStateByStateIso = exports.findUSStateByCounty = exports.findUSStateByName = exports.getInitialValues = exports.getDeliveryOptionIconName = exports.validateAddressFields = exports.validatePhoneNumber = exports.validateVatFields = exports.validateCustomerFields = exports.isValidateInvoicePart = exports.isUkCountry = exports.getHouseNumberNLPattern = exports.isDaysOption = exports.getActualDeliveryValues = exports.generateFieldName = void 0;
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const commonjs_1 = require("jsvat/lib/commonjs");
const ScrollUtil_1 = require("./ScrollUtil");
const CustomerUtil_1 = require("./CustomerUtil");
const generateFieldName = (name, prefix) => prefix ? `${prefix}${prefix.endsWith('.') ? name : (0, common_utils_1.capitalizeOnly)(name)}` : name;
exports.generateFieldName = generateFieldName;
const getActualDeliveryValues = (deliveryAddress, values) => (Object.assign(Object.assign({}, values), deliveryAddress));
exports.getActualDeliveryValues = getActualDeliveryValues;
const isDaysOption = (deliveryOption) => [common_types_1.DeliveryOptionsEnum.NAMED_DAY, common_types_1.DeliveryOptionsEnum.NEXT_DAY, common_types_1.DeliveryOptionsEnum.HOME_DELIVERY].includes(deliveryOption);
exports.isDaysOption = isDaysOption;
const getHouseNumberNLPattern = (value) => new RegExp('^[A-Z]{1}', 'i').test(value) ? common_types_1.DefaultSettings.HOUSE_NUMBER_NL_ALPHA : common_types_1.DefaultSettings.HOUSE_NUMBER_NL;
exports.getHouseNumberNLPattern = getHouseNumberNLPattern;
const isUkCountry = (country) => ['GB', 'GG', 'JE'].includes(country);
exports.isUkCountry = isUkCountry;
const isValidateInvoicePart = (isInvoiceEnabled, isEnableFullVatForm, values) => {
    if (!isInvoiceEnabled) {
        return false;
    }
    return (isEnableFullVatForm && values.needInvoice) || (!isEnableFullVatForm && values.alternateInvoiceAddress);
};
exports.isValidateInvoicePart = isValidateInvoicePart;
const validateCustomerFields = (values, t) => {
    const errors = {};
    if (!values.idTitle) {
        errors.idTitle = t(common_types_1.ErrorMessage.REQUIRED);
    }
    if (!values.firstName) {
        errors.firstName = t(common_types_1.ErrorMessage.REQUIRED);
    }
    if (!values.lastName) {
        errors.lastName = t(common_types_1.ErrorMessage.REQUIRED);
    }
    return errors;
};
exports.validateCustomerFields = validateCustomerFields;
const validateVatFields = (values, t, invoiceHideCompanyName, country) => {
    const errors = {};
    if (!invoiceHideCompanyName && !values.invoiceCompanyName) {
        errors.invoiceCompanyName = t(common_types_1.ErrorMessage.REQUIRED);
    }
    if (values.invoiceVatNumber) {
        const currentCountry = commonjs_1.countries.find(item => item.codes.includes(country));
        const vatCheckResult = (0, commonjs_1.checkVAT)(values.invoiceVatNumber, [currentCountry]);
        if (!vatCheckResult.isValid) {
            errors.invoiceVatNumber = t('buy.error.no.results');
        }
    }
    return errors;
};
exports.validateVatFields = validateVatFields;
const validatePhoneNumber = (phoneNumber, requiresPhoneNumber, t, prefix) => {
    if (requiresPhoneNumber && !phoneNumber) {
        return { [(0, exports.generateFieldName)('phoneNumber', prefix)]: t(common_types_1.ErrorMessage.REQUIRED) };
    }
    else if (phoneNumber && !(0, cotopaxi_1.isPossiblePhoneNumber)(phoneNumber)) {
        return { [(0, exports.generateFieldName)('phoneNumber', prefix)]: t(common_types_1.ErrorMessage.INVALID_PHONE) };
    }
    else {
        return {};
    }
};
exports.validatePhoneNumber = validatePhoneNumber;
const validateAddressFields = (values, t, country, prefix, showPhoneNumber, states, requiresPhoneNumber) => {
    let errors = {};
    if (showPhoneNumber) {
        errors = Object.assign(Object.assign({}, errors), (0, exports.validatePhoneNumber)(values === null || values === void 0 ? void 0 : values.phoneNumber, requiresPhoneNumber, t, prefix));
    }
    switch (country) {
        case 'NL':
            if (!values[(0, exports.generateFieldName)('postCode', prefix)]) {
                errors[(0, exports.generateFieldName)('postCode', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            else if (!new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_NL, 'i').test(values[(0, exports.generateFieldName)('postCode', prefix)])) {
                errors[(0, exports.generateFieldName)('postCode', prefix)] = t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE_NL);
            }
            if (!values[(0, exports.generateFieldName)('houseNumber', prefix)]) {
                errors[(0, exports.generateFieldName)('houseNumber', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            else if (!new RegExp((0, exports.getHouseNumberNLPattern)(values[(0, exports.generateFieldName)('houseNumber', prefix)]), 'i').test(values[(0, exports.generateFieldName)('houseNumber', prefix)])) {
                errors[(0, exports.generateFieldName)('houseNumber', prefix)] = t(common_types_1.ErrorMessage.INVALID_HOUSE_NUMBER_NL);
            }
            if (!values[(0, exports.generateFieldName)('address', prefix)]) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            else if (!new RegExp(common_types_1.DefaultSettings.STREET_NAME_REGEX).test(values[(0, exports.generateFieldName)('address', prefix)])) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t(common_types_1.ErrorMessage.INVALID_STREET);
            }
            if (!values[(0, exports.generateFieldName)('city', prefix)]) {
                errors[(0, exports.generateFieldName)('city', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            break;
        case 'DE':
            if (!values[(0, exports.generateFieldName)('address', prefix)]) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (values[(0, exports.generateFieldName)('address', prefix)] &&
                !new RegExp(common_types_1.DefaultSettings.STREET_NAME_REGEX).test(values[(0, exports.generateFieldName)('address', prefix)])) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t('buy:buy.error.addressfields.streetName.invalid');
            }
            if (!values[(0, exports.generateFieldName)('houseNumber', prefix)]) {
                errors[(0, exports.generateFieldName)('houseNumber', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (!values[(0, exports.generateFieldName)('postCode', prefix)]) {
                errors[(0, exports.generateFieldName)('postCode', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (!values[(0, exports.generateFieldName)('city', prefix)]) {
                errors[(0, exports.generateFieldName)('city', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            break;
        case 'FR':
            if (!values[(0, exports.generateFieldName)('address', prefix)]) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (values[(0, exports.generateFieldName)('address', prefix)] &&
                !new RegExp(common_types_1.DefaultSettings.STREET_NAME_REGEX).test(values[(0, exports.generateFieldName)('address', prefix)])) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t('buy:buy.error.addressfields.streetName.invalid');
            }
            if (!values[(0, exports.generateFieldName)('houseNumber', prefix)]) {
                errors[(0, exports.generateFieldName)('houseNumber', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (!values[(0, exports.generateFieldName)('postCode', prefix)]) {
                errors[(0, exports.generateFieldName)('postCode', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (!values[(0, exports.generateFieldName)('city', prefix)]) {
                errors[(0, exports.generateFieldName)('city', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            break;
        case 'US':
            if (!values[(0, exports.generateFieldName)('address', prefix)]) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (!values[(0, exports.generateFieldName)('city', prefix)]) {
                errors[(0, exports.generateFieldName)('city', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (!values[(0, exports.generateFieldName)('postCode', prefix)]) {
                errors[(0, exports.generateFieldName)('postCode', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (states && !values[(0, exports.generateFieldName)('state', prefix)]) {
                errors[(0, exports.generateFieldName)('state', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            break;
        case 'BE':
        case 'LU':
            if (!values[(0, exports.generateFieldName)('address', prefix)]) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (values[(0, exports.generateFieldName)('address', prefix)] &&
                !new RegExp(common_types_1.DefaultSettings.STREET_BE_LU_REGEXP).test(values[(0, exports.generateFieldName)('address', prefix)])) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t(common_types_1.ErrorMessage.INVALID_STREET_BE_LU);
            }
            if (!values[(0, exports.generateFieldName)('houseNumber', prefix)]) {
                errors[(0, exports.generateFieldName)('houseNumber', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (values[(0, exports.generateFieldName)('houseNumber', prefix)] &&
                !new RegExp(common_types_1.DefaultSettings.HOUSE_NUMBER_BE_LU_REGEXP).test(values[(0, exports.generateFieldName)('houseNumber', prefix)])) {
                errors[(0, exports.generateFieldName)('houseNumber', prefix)] = t(common_types_1.ErrorMessage.INVALID_HOUSE_NUMBER_BE_LU);
            }
            if (values[(0, exports.generateFieldName)('houseNumberAddition', prefix)] &&
                !new RegExp(common_types_1.DefaultSettings.HOUSE_NUMBER_ADDITION_BE_LU_REGEXP).test(values[(0, exports.generateFieldName)('houseNumberAddition', prefix)])) {
                errors[(0, exports.generateFieldName)('houseNumberAddition', prefix)] = t(common_types_1.ErrorMessage.INVALID_HOUSE_NUMBER_ADDITION_BE_LU);
            }
            if (!values[(0, exports.generateFieldName)('postCode', prefix)]) {
                errors[(0, exports.generateFieldName)('postCode', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            else if (country === 'LU' &&
                values[(0, exports.generateFieldName)('postCode', prefix)].length < common_types_1.DefaultSettings.MIN_LENGTH_POST_CODE) {
                errors[(0, exports.generateFieldName)('postCode', prefix)] = t(common_types_1.ErrorMessage.MIN_LENGTH);
            }
            else if (country === 'BE' &&
                !new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_BE, 'i').test(values[(0, exports.generateFieldName)('postCode', prefix)])) {
                errors[(0, exports.generateFieldName)('postCode', prefix)] = t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE_BE);
            }
            if (!values[(0, exports.generateFieldName)('city', prefix)]) {
                errors[(0, exports.generateFieldName)('city', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            break;
        case 'GB':
        case 'GG':
        case 'JE':
            if (!values[(0, exports.generateFieldName)('address', prefix)]) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            else if (!new RegExp(common_types_1.DefaultSettings.STREET_NAME_REGEX).test(values[(0, exports.generateFieldName)('address', prefix)])) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t(common_types_1.ErrorMessage.INVALID_STREET);
            }
            if (!values[(0, exports.generateFieldName)('postCode', prefix)]) {
                errors[(0, exports.generateFieldName)('postCode', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            else if (!new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_UK).test(values[(0, exports.generateFieldName)('postCode', prefix)])) {
                errors[(0, exports.generateFieldName)('postCode', prefix)] = t(common_types_1.ErrorMessage.INVALID_POSTAL_CODE_UK);
            }
            if (!values[(0, exports.generateFieldName)('city', prefix)]) {
                errors[(0, exports.generateFieldName)('city', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            break;
        case 'BFPO':
        case 'IE':
        case 'AU':
        case 'NZ':
        default:
            if (!values[(0, exports.generateFieldName)('address', prefix)]) {
                errors[(0, exports.generateFieldName)('address', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (!values[(0, exports.generateFieldName)('city', prefix)]) {
                errors[(0, exports.generateFieldName)('city', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
            if (!values[(0, exports.generateFieldName)('postCode', prefix)]) {
                errors[(0, exports.generateFieldName)('postCode', prefix)] = t(common_types_1.ErrorMessage.REQUIRED);
            }
    }
    return errors;
};
exports.validateAddressFields = validateAddressFields;
const getDeliveryOptionIconName = (optionName) => {
    switch (optionName) {
        case common_types_1.DeliveryOptionsEnum.COLLECT_HQ:
            return cotopaxi_1.IconName.COMMERCIAL_BUILDING;
        case common_types_1.DeliveryOptionsEnum.COLLECT_STORE:
            return cotopaxi_1.IconName.STORE;
        case common_types_1.DeliveryOptionsEnum.HOME_DELIVERY:
        case common_types_1.DeliveryOptionsEnum.NAMED_DAY:
        case common_types_1.DeliveryOptionsEnum.NEXT_DAY:
            return cotopaxi_1.IconName.HOME;
        case common_types_1.DeliveryOptionsEnum.PICKUP_POINT:
        default:
            return cotopaxi_1.IconName.MAP_MARKER;
    }
};
exports.getDeliveryOptionIconName = getDeliveryOptionIconName;
const getInitialValues = (delivery, initValues, optionName, addressPrefix, addressPrefilled = true, addressEdit = false, prefillProfilePhoneNumber = false) => {
    if (!delivery.customerDetails) {
        return initValues;
    }
    let address = addressPrefilled &&
        (optionName === common_types_1.DeliveryOptionsEnum.PICKUP_POINT ? delivery.customerDetails.address : delivery.deliveryAddress);
    if (addressEdit) {
        address = delivery.deliveryAddress;
    }
    let initialValues = Object.assign(Object.assign({}, initValues), address);
    if (![common_types_1.CountryId.NL, common_types_1.CountryId.BE, common_types_1.CountryId.LU, common_types_1.CountryId.FR].includes(delivery.idCountry)) {
        initialValues.houseNumberAddition = '';
    }
    if (![common_types_1.CountryId.NL, common_types_1.CountryId.BE, common_types_1.CountryId.LU, common_types_1.CountryId.DE, common_types_1.CountryId.FR].includes(delivery.idCountry)) {
        initialValues.houseNumber = '';
    }
    if ([common_types_1.CountryId.NL, common_types_1.CountryId.BE, common_types_1.CountryId.LU, common_types_1.CountryId.DE, common_types_1.CountryId.FR].includes(delivery.idCountry)) {
        initialValues.addressLine2 = '';
    }
    if (common_types_1.CountryId.US !== delivery.idCountry) {
        initialValues.state = '';
    }
    if (![common_types_1.CountryId.GB, common_types_1.CountryId.GG, common_types_1.CountryId.JE].includes(delivery.idCountry)) {
        initialValues.addressLine3 = '';
    }
    if (addressPrefix && delivery.customerDetails) {
        const addressWithPrefix = {};
        if (delivery.customerDetails.address) {
            for (const [key, value] of Object.entries(delivery.customerDetails.address)) {
                addressWithPrefix[`${addressPrefix}${(0, common_utils_1.capitalizeOnly)(key)}`] = value;
            }
        }
        if (delivery.customerDetails.vatNumber) {
            addressWithPrefix[`${addressPrefix}${(0, common_utils_1.capitalizeOnly)('vatNumber')}`] = delivery.customerDetails.vatNumber;
        }
        if (delivery.customerDetails.companyName) {
            addressWithPrefix[`${addressPrefix}${(0, common_utils_1.capitalizeOnly)('companyName')}`] = delivery.customerDetails.companyName;
        }
        if (delivery.customerDetails.companyDepartment) {
            addressWithPrefix[`${addressPrefix}${(0, common_utils_1.capitalizeOnly)('companyDepartment')}`] =
                delivery.customerDetails.companyDepartment;
        }
        initialValues = Object.assign(Object.assign({}, initialValues), addressWithPrefix);
    }
    initialValues = Object.assign(Object.assign({}, initialValues), CustomerUtil_1.default.getCustomerNames(delivery, optionName));
    if (!(0, common_utils_1.isNullOrUndefined)(delivery.deliveryAddress.phoneNumber)) {
        initialValues = Object.assign(Object.assign({}, initialValues), { phoneNumber: CustomerUtil_1.default.convertPhoneNumberPrefix(delivery.deliveryAddress.phoneNumber, '00', '+') });
    }
    else if (prefillProfilePhoneNumber && delivery.customerDetails.mobilePhoneNumber) {
        initialValues = Object.assign(Object.assign({}, initialValues), { phoneNumber: CustomerUtil_1.default.convertPhoneNumberPrefix(delivery.customerDetails.mobilePhoneNumber, '00', '+') });
    }
    return initialValues;
};
exports.getInitialValues = getInitialValues;
const findUSStateByName = (states, name) => states === null || states === void 0 ? void 0 : states.find(st => name === st.name);
exports.findUSStateByName = findUSStateByName;
const findUSStateByCounty = (states, deliveryAddress) => states === null || states === void 0 ? void 0 : states.find(st => (deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.idCountry) === st.countryId && (deliveryAddress === null || deliveryAddress === void 0 ? void 0 : deliveryAddress.county) === st.name);
exports.findUSStateByCounty = findUSStateByCounty;
const findUSStateByStateIso = (states, deliveryAddress) => states === null || states === void 0 ? void 0 : states.find(st => deliveryAddress.idCountry === st.countryId && deliveryAddress.stateIso === st.abbreviation);
exports.findUSStateByStateIso = findUSStateByStateIso;
const isNextOrNamedDayOptions = (deliveryOptions) => {
    let countOptions = 0;
    deliveryOptions.forEach(opt => {
        if (opt.name === common_types_1.DeliveryOptionsEnum.NAMED_DAY || opt.name === common_types_1.DeliveryOptionsEnum.NEXT_DAY) {
            countOptions++;
        }
    });
    return countOptions === 2;
};
exports.isNextOrNamedDayOptions = isNextOrNamedDayOptions;
const isNamedDayOrHomeDeliveryOptions = (deliveryOptions) => {
    let countOptions = 0;
    deliveryOptions.forEach(opt => {
        if (opt.name === common_types_1.DeliveryOptionsEnum.NAMED_DAY || opt.name === common_types_1.DeliveryOptionsEnum.HOME_DELIVERY) {
            countOptions++;
        }
    });
    return countOptions === 2;
};
exports.isNamedDayOrHomeDeliveryOptions = isNamedDayOrHomeDeliveryOptions;
const isAllDaysOptions = (deliveryOptions) => {
    let countOptions = 0;
    deliveryOptions.forEach(opt => {
        if (opt.name === common_types_1.DeliveryOptionsEnum.NAMED_DAY ||
            opt.name === common_types_1.DeliveryOptionsEnum.NEXT_DAY ||
            opt.name === common_types_1.DeliveryOptionsEnum.HOME_DELIVERY) {
            countOptions++;
        }
    });
    return countOptions === 3;
};
exports.isAllDaysOptions = isAllDaysOptions;
const chooseDeliveryOption = (deliveryOptions, deliveryOption) => (0, exports.isDaysOption)(deliveryOption) &&
    ((0, exports.isNextOrNamedDayOptions)(deliveryOptions) ||
        (0, exports.isNamedDayOrHomeDeliveryOptions)(deliveryOptions) ||
        (0, exports.isAllDaysOptions)(deliveryOptions))
    ? common_types_1.DeliveryOptionsEnum.NAMED_DAY
    : deliveryOption;
exports.chooseDeliveryOption = chooseDeliveryOption;
const filterDeliveryOptions = (deliveryOptions) => {
    if ((0, exports.isNextOrNamedDayOptions)(deliveryOptions) ||
        (0, exports.isNamedDayOrHomeDeliveryOptions)(deliveryOptions) ||
        (0, exports.isAllDaysOptions)(deliveryOptions)) {
        return deliveryOptions.filter(opt => opt.name !== common_types_1.DeliveryOptionsEnum.NEXT_DAY && opt.name !== common_types_1.DeliveryOptionsEnum.HOME_DELIVERY);
    }
    return deliveryOptions;
};
exports.filterDeliveryOptions = filterDeliveryOptions;
const showDeliveryDates = (options) => options.some(opt => opt.name === common_types_1.DeliveryOptionsEnum.NAMED_DAY || (opt.name === common_types_1.DeliveryOptionsEnum.NEXT_DAY && opt.namedDayEnabled));
exports.showDeliveryDates = showDeliveryDates;
const showNamedDays = (option) => option.name === common_types_1.DeliveryOptionsEnum.NAMED_DAY ||
    (option.name === common_types_1.DeliveryOptionsEnum.NEXT_DAY && option.namedDayEnabled);
exports.showNamedDays = showNamedDays;
const buyFormHandleErrors = (errors) => {
    ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.querySelector(`.as-m-check-list-item--active [for="buy-${errors[0]}"]`));
};
exports.buyFormHandleErrors = buyFormHandleErrors;
const getDefaultCountry = (fictiveWebShop) => {
    switch (fictiveWebShop) {
        case '139':
            return 'IE';
        case '142':
            return 'US';
        case '145':
            return 'AU';
        default:
            return 'GB';
    }
};
exports.getDefaultCountry = getDefaultCountry;
const isValidAddress = (postCode, houseNumber, country) => {
    switch (country) {
        case 'NL':
            return (!!postCode &&
                !!houseNumber &&
                new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_NL, 'i').test(postCode) &&
                new RegExp((0, exports.getHouseNumberNLPattern)(houseNumber), 'i').test(houseNumber));
        case 'BE':
            return !!postCode && new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_BE, 'i').test(postCode);
        case 'GB':
        case 'GG':
        case 'JE':
            return !!postCode && new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_UK).test(postCode);
        default:
            return true;
    }
};
exports.isValidAddress = isValidAddress;
const isValidPostCode = (postCode, country) => {
    switch (country) {
        case 'BE':
            return !!postCode && new RegExp(common_types_1.DefaultSettings.POSTAL_CODE_BE, 'i').test(postCode);
        case 'DE':
        case 'FR':
            return !!postCode && postCode.length === 5;
        default:
            return true;
    }
};
exports.isValidPostCode = isValidPostCode;
