"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const StoreFinderGeneral_1 = require("./store-finder-general/StoreFinderGeneral");
const StoreList_1 = require("./store-list/StoreList");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const ConfigurationUtil_1 = require("../../../util/ConfigurationUtil");
const StoreAPI_1 = require("../../../api/store/StoreAPI");
const SearchAPI_1 = require("../../../api/search/SearchAPI");
const OsmApi_1 = require("../../../api/open-street-map/OsmApi");
const LocationUtil_1 = require("../../../util/LocationUtil");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ZendeskWidgetUtil_1 = require("../../../util/ZendeskWidgetUtil");
const common_utils_1 = require("@as-react/common-utils");
const StoreUtil_1 = require("../../../util/StoreUtil");
const StorageKeysConstants_1 = require("../../../constants/StorageKeysConstants");
const LocalStorageUtil_1 = require("../../../util/LocalStorageUtil");
const StoreFinderForm_1 = require("../store-finder-form/StoreFinderForm");
const SessionSelector_1 = require("../../../redux/session/selectors/SessionSelector");
const CustomerSelector_1 = require("../../../redux/customer/selectors/CustomerSelector");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const StoreFinderConstants_1 = require("../../../constants/StoreFinderConstants");
const StoreListLoader_1 = require("./store-list-loader/StoreListLoader");
const BasketSelector_1 = require("../../../redux/basket/selectors/BasketSelector");
// https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
var GeolocationPositionErrorCode;
(function (GeolocationPositionErrorCode) {
    GeolocationPositionErrorCode[GeolocationPositionErrorCode["PERMISSION_DENIED"] = 1] = "PERMISSION_DENIED";
    GeolocationPositionErrorCode[GeolocationPositionErrorCode["POSITION_UNAVAILABLE"] = 2] = "POSITION_UNAVAILABLE";
    GeolocationPositionErrorCode[GeolocationPositionErrorCode["TIMEOUT"] = 3] = "TIMEOUT";
})(GeolocationPositionErrorCode || (GeolocationPositionErrorCode = {}));
const initLocation = {
    lat: '',
    lng: '',
};
const initLocationSearchResult = {
    location: initLocation,
    locationName: '',
    type: common_types_1.LocationSearchType.DEFAULT,
    state: 'INIT',
};
const StoreFinderPopup = ({ addToBasket, addToFilter, fredhopperSegment, handleClosePopup, setClickAndCollectStoreId, isOpen, selectedSku, urlInfo, type = common_types_1.StoreFinderType.CLICK_AND_COLLECT, disabledAddToBasket = false, title, description, storeSourceExcluded, allowMultiFascia, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isLoggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetIsLoggedIn)());
    const profile = (0, react_redux_1.useSelector)((0, CustomerSelector_1.makeGetCustomerProfile)());
    const specialtyType = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSpecialtyType)());
    const products = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketMappedProductsState)());
    const productInfo = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductInfo)());
    const configuration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultRequestParams = (0, ConfigurationUtil_1.getDefaultRequestParameters)(configuration);
    const fictiveWebshop = (0, ConfigurationUtil_1.getFictiveWebShop)(configuration);
    const authenticationConfig = (0, ConfigurationUtil_1.getAuthenticationConfig)(configuration);
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(configuration);
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(configuration);
    const defaultCountryCode = (0, ConfigurationUtil_1.getDefaultCountryCode)(configuration);
    const countryCodes = (0, ConfigurationUtil_1.getCountryCodes)(configuration);
    const businessUnitId = (0, ConfigurationUtil_1.getBusinessUnitId)(configuration);
    const { stores_own_fascia_first: fasciaFirst } = (0, ConfigurationUtil_1.getFeatureToggles)(configuration);
    const [location, setLocation] = (0, react_1.useState)(initLocation);
    const [isLocationBlocked, setIsLocationBlocked] = (0, react_1.useState)(false);
    const [hasUserLocation, setHasUserLocation] = (0, react_1.useState)(false);
    const [locationSearchResult, setLocationSearchResult] = (0, react_1.useState)(initLocationSearchResult);
    const [stores, setStores] = (0, react_1.useState)([]);
    const [isShowStock, setIsShowStock] = (0, react_1.useState)(!!specialtyType);
    const [valuesForm, setValuesForm] = (0, react_1.useState)(StoreFinderForm_1.initialValuesStoreFinder);
    const [currentSku, setCurrentSku] = (0, react_1.useState)('');
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [isLocationChecked, setIsLocationChecked] = (0, react_1.useState)(false);
    const [isProfileLocationSearchLoaded, setIsProfileLocationSearchLoaded] = (0, react_1.useState)(false);
    const [geocodeError, setGeocodeError] = (0, react_1.useState)();
    const [errorFeedback, setErrorFeedback] = (0, react_1.useState)();
    const [manualSearch, setManualSearch] = (0, react_1.useState)(false);
    const resetToInitialState = () => {
        setStores([]);
        setIsLoading(true);
        setValuesForm(StoreFinderForm_1.initialValuesStoreFinder);
        setIsLocationChecked(false);
        setErrorFeedback(undefined);
        setGeocodeError(undefined);
        setLocationSearchResult(initLocationSearchResult);
        setIsShowStock(!!specialtyType);
    };
    const onPopoverClose = () => {
        resetToInitialState();
        handleClosePopup();
    };
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)({ close: onPopoverClose });
    const onLocationSearched = (result) => {
        if (isShoplanding) {
            setLocationSearchResult(result);
        }
        else {
            common_utils_1.CookieUtil.set(common_types_1.Cookie.BROWSER_GEOLOCATION, encodeURIComponent(JSON.stringify(result)), { secure: true });
        }
    };
    const checkLocation = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setIsLocationChecked(false);
        try {
            const browserLocation = yield LocationUtil_1.default.getLocationFromBrowser();
            setLocation(browserLocation);
            setHasUserLocation(true);
            setIsLocationBlocked(false);
            setIsLocationChecked(true);
        }
        catch (error) {
            const { code } = error;
            if (Number(code) === GeolocationPositionErrorCode.PERMISSION_DENIED) {
                setLocationSearchResult(Object.assign(Object.assign({}, initLocationSearchResult), { state: 'SET' }));
            }
            setIsLocationBlocked(true);
            setIsLocationChecked(true);
        }
    });
    const getShoplandingLocation = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const storeNumber = LocalStorageUtil_1.default.getJsonItem(StorageKeysConstants_1.SHOP_NUMBER_STORAGE_KEY);
        if (!storeNumber) {
            return;
        }
        const shoplandingInfoResponse = yield StoreAPI_1.default.getShoplandingInfo(servicesEndpoint, defaultRequestParams.market, storeNumber);
        if (shoplandingInfoResponse === null || shoplandingInfoResponse === void 0 ? void 0 : shoplandingInfoResponse.geographic) {
            const tmpLocationSearchResult = {
                location: {
                    lat: shoplandingInfoResponse.geographic.latitude,
                    lng: shoplandingInfoResponse.geographic.longitude,
                },
                locationName: '',
                type: common_types_1.LocationSearchType.DEFAULT,
                state: 'SET',
            };
            onLocationSearched(tmpLocationSearchResult);
            setIsLocationChecked(true);
        }
        else {
            setIsLocationChecked(true);
            setIsLoading(false);
            setErrorFeedback(t('product.check.search.noLocationFound'));
        }
    });
    const parseCookieLocation = (value) => {
        let tmpLocationSearchResult = initLocationSearchResult;
        if (value) {
            const parsed = JSON.parse(decodeURIComponent(value));
            if ((0, common_types_1.isLocationSearchResult)(parsed)) {
                tmpLocationSearchResult = parsed;
            }
            else {
                tmpLocationSearchResult.location = parsed;
            }
        }
        setLocationSearchResult(tmpLocationSearchResult);
    };
    const getAppliedFilters = () => {
        var _a;
        const appliedFilters = ((_a = urlInfo === null || urlInfo === void 0 ? void 0 : urlInfo.filter) === null || _a === void 0 ? void 0 : _a.length)
            ? urlInfo.filter.map(filter => `${filter.filterOn}:${filter.filterValue}`)
            : [];
        return (urlInfo === null || urlInfo === void 0 ? void 0 : urlInfo.defaultFilter) ? appliedFilters.concat(urlInfo.defaultFilter) : appliedFilters;
    };
    const getSortBy = () => {
        var _a;
        if (geocodeError) {
            return 'storeName';
        }
        if (locationSearchResult.type === common_types_1.LocationSearchType.CUSTOM_SEARCH || isShoplanding) {
            return 'distance';
        }
        if (!isLoggedIn) {
            return isLocationBlocked ? 'storeName' : 'distance';
        }
        else {
            return ((_a = profile === null || profile === void 0 ? void 0 : profile.address) === null || _a === void 0 ? void 0 : _a.city) ? 'distance' : isLocationBlocked ? 'storeName' : 'distance';
        }
    };
    const getProfileLocationSearch = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsProfileLocationSearchLoaded(false);
        if ((_a = profile === null || profile === void 0 ? void 0 : profile.address) === null || _a === void 0 ? void 0 : _a.city) {
            const answer = yield OsmApi_1.default.getLocationFromOSM(profile.address.city);
            const sortAnswerByRank = answer
                .filter(place => StoreFinderConstants_1.LOCATION_TYPE.includes(place.type))
                .sort((a, b) => b.importance - a.importance);
            if (sortAnswerByRank.length > 0) {
                const tmpLocationSearchResult = {
                    location: {
                        lat: sortAnswerByRank[0].lat,
                        lng: sortAnswerByRank[0].lon,
                    },
                    locationName: profile.address.city,
                    type: common_types_1.LocationSearchType.SHARE_MY_LOCATION,
                    state: 'SET',
                };
                onLocationSearched(tmpLocationSearchResult);
            }
        }
        setIsProfileLocationSearchLoaded(true);
    });
    const getStoreData = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        setErrorFeedback(undefined);
        setIsLoading(true);
        setStores([]);
        let storeData;
        try {
            const selectedStoreId = !manualSearch ? LocalStorageUtil_1.default.getItem(StorageKeysConstants_1.SELECTED_STORE_ID_STORAGE_KEY) : null;
            if (type === common_types_1.StoreFinderType.IN_STORE_FILTER || type === common_types_1.StoreFinderType.LOCATOR) {
                storeData = yield StoreAPI_1.default.getClickAndCollectStores(servicesEndpoint, defaultRequestParams, locationSearchResult.location, type === common_types_1.StoreFinderType.IN_STORE_FILTER, !!allowMultiFascia, getSortBy(), selectedStoreId);
            }
            else {
                const productData = (productInfo === null || productInfo === void 0 ? void 0 : productInfo.productId)
                    ? [
                        {
                            skuId: Number(selectedSku),
                            productId: Number(productInfo.productId),
                            colorId: Number(productInfo.selectedColorId),
                            quantity: 1,
                        },
                    ]
                    : products
                        .filter(product => product.productType === common_types_1.ProductTypes.PRODUCT)
                        .map(product => ({
                        skuId: Number(product.sku),
                        productId: Number(product.productId),
                        colorId: Number(product.colorId),
                        quantity: product.quantity,
                    }));
                storeData = yield StoreAPI_1.default.getClickAndCollectStoresOpeningsHours(servicesEndpoint, defaultRequestParams, businessUnitId, fictiveWebshop, productData, locationSearchResult.location, specialtyType ? false : isShowStock, isShowStock && !!specialtyType, getSortBy(), isShoplanding, selectedStoreId);
            }
            if (type === common_types_1.StoreFinderType.IN_STORE_FILTER) {
                const productCountPerStore = yield SearchAPI_1.default.getProductCountPerStore(servicesEndpoint, authenticationConfig, fredhopperSegment, urlInfo, isShoplanding, getAppliedFilters());
                if (productCountPerStore.length) {
                    storeData.forEach(store => {
                        var _a;
                        store.itemsCount = (_a = productCountPerStore.find(item => item.storeId === store.storeId)) === null || _a === void 0 ? void 0 : _a.itemCount;
                    });
                }
            }
            setIsLoading(false);
            setStores(storeData);
        }
        catch (error) {
            setErrorFeedback(type === common_types_1.StoreFinderType.BASKET
                ? t('storeFinder.popup.basket.not.in.stock')
                : t('storeFinder.popup.not.in.stock'));
            setIsLoading(false);
            setStores([]);
        }
        setManualSearch(false);
    });
    const getUserAddressFromOSM = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const osmAddress = yield OsmApi_1.default.getAddressFromOSM(location);
        if (osmAddress.error) {
            setGeocodeError(t('storeFinder.popup.unable.to.geocode'));
            setLocationSearchResult(Object.assign(Object.assign({}, initLocationSearchResult), { state: 'SET' }));
            return;
        }
        const locationName = `${osmAddress.address.road ? osmAddress.address.road : ''}${osmAddress.address.road && (osmAddress.address.town || osmAddress.address.city) ? ', ' : ''}${osmAddress.address.town ? osmAddress.address.town : osmAddress.address.city ? osmAddress.address.city : ''}`;
        const userLocation = {
            location: {
                lat: osmAddress.lat,
                lng: osmAddress.lon,
            },
            locationName,
            type: common_types_1.LocationSearchType.SHARE_MY_LOCATION,
            state: 'SET',
        };
        onLocationSearched(userLocation);
        setValuesForm({ location: locationName });
    });
    const handleSubmitLocation = (values, actions) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        const { location: formLocation } = values;
        if (formLocation.trim() !== '') {
            const isPostCode = StoreUtil_1.default.isSearchByPostCode(formLocation);
            const answer = yield OsmApi_1.default.getLocationFromOSM(isPostCode ? StoreUtil_1.default.formatPostCode(formLocation, defaultCountryCode) : formLocation, isPostCode ? defaultCountryCode : countryCodes);
            const sortAnswerByRank = answer
                .filter(place => StoreFinderConstants_1.LOCATION_TYPE.includes(place.type))
                .sort((a, b) => b.importance - a.importance);
            if (sortAnswerByRank.length > 0) {
                const tmpLocationSearchResult = {
                    location: {
                        lat: sortAnswerByRank[0].lat,
                        lng: sortAnswerByRank[0].lon,
                    },
                    locationName: formLocation,
                    type: common_types_1.LocationSearchType.CUSTOM_SEARCH,
                    state: 'SET',
                };
                onLocationSearched(tmpLocationSearchResult);
                setValuesForm({ location: formLocation });
                setManualSearch(true);
            }
            else {
                actions.setFieldError('location', t('product.check.search.noLocationFound'));
                setValuesForm(StoreFinderForm_1.initialValuesStoreFinder);
            }
            setGeocodeError(undefined);
        }
    });
    const handleOnStoreClick = (_b) => tslib_1.__awaiter(void 0, [_b], void 0, function* ({ storeId, storeName }) {
        if (type === common_types_1.StoreFinderType.CLICK_AND_COLLECT || type === common_types_1.StoreFinderType.BASKET) {
            yield StoreAPI_1.default.setClickAndCollectStoreId(servicesEndpoint, defaultRequestParams, storeId);
            if (type === common_types_1.StoreFinderType.CLICK_AND_COLLECT) {
                addToBasket === null || addToBasket === void 0 ? void 0 : addToBasket(1);
            }
            if (type === common_types_1.StoreFinderType.BASKET) {
                setClickAndCollectStoreId === null || setClickAndCollectStoreId === void 0 ? void 0 : setClickAndCollectStoreId(storeId);
            }
        }
        else if (type === common_types_1.StoreFinderType.IN_STORE_FILTER) {
            addToFilter === null || addToFilter === void 0 ? void 0 : addToFilter(storeId, storeName);
        }
        LocalStorageUtil_1.default.setItem(StorageKeysConstants_1.SELECTED_STORE_ID_STORAGE_KEY, storeId);
        handlePopoverClose();
        handleClosePopup();
    });
    const handleChangeStock = () => setIsShowStock(prevState => !prevState);
    const handleResetValues = () => setValuesForm(StoreFinderForm_1.initialValuesStoreFinder);
    const closeStoreFinderPopup = () => {
        handlePopoverClose();
        handleClosePopup();
        ZendeskWidgetUtil_1.default.resetZIndex();
    };
    (0, react_1.useEffect)(() => {
        if (isOpen) {
            handlePopoverOpen(null, null, true);
        }
    }, [handlePopoverOpen, isOpen]);
    const checkBrowserAndProfileLocation = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (isShoplanding) {
            yield getShoplandingLocation();
        }
        else {
            if (!isLocationChecked) {
                yield checkLocation();
            }
            if (!isLocationBlocked && hasUserLocation) {
                yield getUserAddressFromOSM();
            }
            if (isLoggedIn && isLocationBlocked) {
                yield getProfileLocationSearch();
            }
        }
    });
    (0, react_1.useEffect)(() => {
        if (isOpen) {
            checkBrowserAndProfileLocation();
        }
    }, [isOpen, isLoggedIn, profile, hasUserLocation, isLocationBlocked]);
    (0, react_1.useEffect)(() => {
        common_utils_1.CookieUtil.addChangeListener(common_types_1.Cookie.BROWSER_GEOLOCATION, parseCookieLocation);
        return () => {
            common_utils_1.CookieUtil.removeChangeListener(common_types_1.Cookie.BROWSER_GEOLOCATION, parseCookieLocation);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        if (isOpen) {
            if (locationSearchResult.state === 'SET' &&
                isLocationChecked &&
                (!isLoggedIn || (isLoggedIn && (isProfileLocationSearchLoaded || hasUserLocation)))) {
                getStoreData();
            }
            const hasLatLng = locationSearchResult.location.lat && locationSearchResult.location.lng;
            const hasSelectedSku = !currentSku && selectedSku;
            if (hasLatLng && hasSelectedSku) {
                setCurrentSku(selectedSku);
            }
        }
    }, [
        isOpen,
        locationSearchResult.location.lat,
        locationSearchResult.location.lng,
        locationSearchResult.state,
        isLoggedIn,
        isProfileLocationSearchLoaded,
        isLocationChecked,
        isShowStock,
    ]);
    (0, react_1.useEffect)(() => {
        if (isOpen && currentSku && selectedSku && currentSku !== selectedSku) {
            getStoreData();
            setCurrentSku(selectedSku);
        }
    }, [isOpen, selectedSku, currentSku]);
    (0, react_1.useEffect)(() => {
        if (type === common_types_1.StoreFinderType.IN_STORE_FILTER && isOpen && stores.length > 0) {
            getStoreData();
        }
    }, [isOpen, type]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Popover, { anchorEl: anchorEl, type: cotopaxi_1.PopoverType.STORE_FINDER, onClose: closeStoreFinderPopup, height: type !== common_types_1.StoreFinderType.IN_STORE_FILTER && !errorFeedback && (isShoplanding || (!isShoplanding && isLoading))
            ? cotopaxi_1.PopoverHeight.LARGE
            : undefined, title: title !== null && title !== void 0 ? title : t('storeFinder.popup.title'), children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.ScrollableList, { variant: cotopaxi_1.ScrollableListVariant.SHADOW, children: [(0, jsx_runtime_1.jsx)(StoreFinderGeneral_1.default, { isShowStock: isShowStock, isShowStockCheckbox: type === common_types_1.StoreFinderType.CLICK_AND_COLLECT || type === common_types_1.StoreFinderType.BASKET, locationSearchType: locationSearchResult.type, valuesForm: valuesForm, handleResetValues: handleResetValues, handleSubmitLocation: handleSubmitLocation, handleChangeStock: handleChangeStock, overwriteSubtitle: description, specialtyType: specialtyType }), geocodeError && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: {
                        spacing: {
                            marginLeft: 3,
                            marginRight: 3,
                        },
                    }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: geocodeError, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }) })), errorFeedback && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 4 } }, tablet: {
                        spacing: {
                            marginLeft: 3,
                            marginRight: 3,
                        },
                    }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { text: errorFeedback, variant: cotopaxi_1.FeedbackMessageVariant.NEGATIVE }) })), isLoading && (0, jsx_runtime_1.jsx)(StoreListLoader_1.StoreListLoader, {}), !!stores.length && !isLoading && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: {
                            marginTop: 2,
                            marginBottom: 2,
                        },
                    }, tablet: {
                        spacing: {
                            marginBottom: 4,
                        },
                    }, children: (0, jsx_runtime_1.jsx)(StoreList_1.StoreList, { stores: stores, servicesEndpoint: servicesEndpoint, businessUnitId: businessUnitId, defaultRequestParams: defaultRequestParams, storeFinderType: type, disabledAddToBasket: disabledAddToBasket, fasciaFirstMessage: fasciaFirst &&
                            type !== common_types_1.StoreFinderType.LOCATOR &&
                            !StoreUtil_1.default.isAllStoresWithSameMainWebShop(defaultRequestParams.mainWebshop, stores), onStoreClick: handleOnStoreClick, storeSourceExcluded: storeSourceExcluded, sortBy: getSortBy(), specialtyType: specialtyType, location: location, isLocationBlocked: isLocationBlocked }) }))] }) }));
};
exports.default = StoreFinderPopup;
