"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddToBasketButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const AddToBasketButton = ({ onClick, quantity = 1, isStickyBar, isOneSizeProduct, disabled = false, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const hideText = !isDesktop && isStickyBar && !isOneSizeProduct;
    const isFill = !isStickyBar || (isStickyBar && isOneSizeProduct);
    const icon = {
        name: cotopaxi_1.IconName.CART,
        size: cotopaxi_1.IconSize.MAJOR,
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { state: disabled ? cotopaxi_1.ButtonState.DISABLED : undefined, fill: isFill, dataQA: "add_to_basket", size: !isStickyBar && cotopaxi_1.ButtonSize.LARGE, onClick: () => onClick(quantity), variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, text: !hideText && t('product:pdp.cart.addtobasket.btn'), icon: hideText && icon, iconLeft: !hideText && icon }));
};
exports.AddToBasketButton = AddToBasketButton;
