"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersMobileList = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const FiltersEmptyResult_1 = require("../../empty-result/FiltersEmptyResult");
const ConfigurationUtil_1 = require("../../../../util/ConfigurationUtil");
const common_components_1 = require("@as-react/common-components");
const FiltersMobileSort_1 = require("./FiltersMobileSort");
const FiltersMobileCollapsible_1 = require("./FiltersMobileCollapsible");
const FiltersMobileList = ({ filters, itemCount, filterWizardConfig, fredhopperGlossary, onFilterItemChange, removeAllActiveFilters, urlFilters, }) => {
    const aemConfiguration = (0, react_1.useContext)(common_components_1.AemConfigurationContext);
    const mainWebShop = (0, ConfigurationUtil_1.getMainWebShop)(aemConfiguration);
    const { sortConfiguration } = (0, ConfigurationUtil_1.getListerConfiguration)(aemConfiguration);
    return itemCount <= 0 ? ((0, jsx_runtime_1.jsx)(FiltersEmptyResult_1.FiltersEmptyResult, { removeAllActiveFilters: removeAllActiveFilters, mainWebShop: mainWebShop, showChangeFilterStep: false })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [sortConfiguration.properties.length > 0 && ((0, jsx_runtime_1.jsx)(FiltersMobileSort_1.FiltersMobileSort, { sortConfiguration: sortConfiguration }, "sort")), filters.map(filter => ((0, jsx_runtime_1.jsx)(FiltersMobileCollapsible_1.FiltersMobileCollapsible, { filter: filter, itemCount: itemCount, onFilterItemChange: onFilterItemChange, fredhopperGlossary: fredhopperGlossary, filterWizardConfig: filterWizardConfig, urlFilters: urlFilters }, filter.filterOn)))] }));
};
exports.FiltersMobileList = FiltersMobileList;
