"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiltersMobile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const DOMElementsConstants_1 = require("../../../../constants/DOMElementsConstants");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const FiltersMobileButton_1 = require("./FiltersMobileButton");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const AppliedFiltersMobile_1 = require("../../applied-filters/AppliedFiltersMobile");
const FiltersMobileList_1 = require("./FiltersMobileList");
var VerticalDirection;
(function (VerticalDirection) {
    VerticalDirection["UP"] = "up";
    VerticalDirection["DOWN"] = "down";
})(VerticalDirection || (VerticalDirection = {}));
const FiltersMobile = ({ buttonText, isContentTileLister, filters, itemCount, onFilterItemChange, removeAllFilters, fredhopperGlossary, filterWizardConfig, urlFilters, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([cotopaxi_1.I18N_NAMESPACES.product]);
    const [navigationEl, setNavigationEl] = (0, react_1.useState)();
    const [isStickyFiltersOpen, setIsStickyFiltersOpen] = (0, react_1.useState)(false);
    const [isButtonHidden, setIsButtonHidden] = (0, react_1.useState)(true);
    const buttonRef = (0, react_1.useRef)(null);
    const displayHandler = (0, cotopaxi_1.useDebounce)((direction, button, navigation) => {
        if (!button || !navigation) {
            return;
        }
        const { top, bottom } = button.getBoundingClientRect();
        const buttonOffset = direction === VerticalDirection.UP ? top : bottom;
        const isNavDisplayed = !navigation.classList.contains(DOMElementsConstants_1.NAVIGATION_HORIZONTAL_COLLAPSE);
        setIsButtonHidden(Boolean(Math.floor(isNavDisplayed ? buttonOffset - DOMElementsConstants_1.NAVIGATION_HEIGHT : top) >= 0));
    }, GeneralConstants_1.DELAY.DELAY_100);
    (0, react_1.useEffect)(() => {
        setNavigationEl(document.querySelector(DOMElementsConstants_1.NAVIGATION_HORIZONTAL));
        displayHandler(VerticalDirection.DOWN, buttonRef.current, navigationEl);
    }, []);
    (0, cotopaxi_1.useScrollPosition)(({ prevPos, currPos }) => {
        const direction = prevPos.y < currPos.y ? VerticalDirection.UP : VerticalDirection.DOWN;
        displayHandler(direction, buttonRef.current, navigationEl);
    }, [buttonRef, navigationEl]);
    const handleIsStickyFiltersOpen = () => {
        setIsStickyFiltersOpen(!isStickyFiltersOpen);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { ref: buttonRef, children: (0, jsx_runtime_1.jsx)(FiltersMobileButton_1.FiltersMobileButton, { text: buttonText, onClick: handleIsStickyFiltersOpen, isContentTileLister: isContentTileLister }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.StickyBar, { isHidden: !isStickyFiltersOpen && isButtonHidden, variant: cotopaxi_1.StickyBarVariant.FILTERS, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.BASE, text: buttonText, fill: true, onClick: handleIsStickyFiltersOpen }) }), (0, jsx_runtime_1.jsx)(andes_react_1.BottomSheet, { isOpen: isStickyFiltersOpen, padding: "slim", onClose: handleIsStickyFiltersOpen, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 8 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.L, children: t('product:product.lister.filter.title') }) }), (0, jsx_runtime_1.jsx)(AppliedFiltersMobile_1.AppliedFiltersMobile, {}), (0, jsx_runtime_1.jsx)(FiltersMobileList_1.FiltersMobileList, { filterWizardConfig: filterWizardConfig, onFilterItemChange: onFilterItemChange, filters: filters, itemCount: itemCount, removeAllActiveFilters: removeAllFilters, fredhopperGlossary: fredhopperGlossary, urlFilters: urlFilters })] }) })] }));
};
exports.FiltersMobile = FiltersMobile;
