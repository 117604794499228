"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AUTH_POPUP_BUTTON_ID = exports.FORGOT_PASSWORD_SUCCESS_FEEDBACK_ID = exports.LOGIN_FORM_ERROR_FEEDBACK_ID = exports.RETURN_PORTAL_GUEST_FORM_ERROR_FEEDBACK_ID = exports.RETURN_ITEMS_COMPONENT = exports.WISHLIST_COMPONENT = exports.LISTER_LAYOUT = exports.NAVIGATION_HORIZONTAL_COLLAPSE = exports.NAVIGATION_HORIZONTAL = exports.NAVIGATION_HEIGHT = exports.MAIN_CONTENT_CONTAINER = exports.HEADER_CONTAINER = void 0;
exports.HEADER_CONTAINER = '.header__container';
exports.MAIN_CONTENT_CONTAINER = '.main-content__container';
exports.NAVIGATION_HEIGHT = 104;
exports.NAVIGATION_HORIZONTAL = '.navigation-horizontal';
exports.NAVIGATION_HORIZONTAL_COLLAPSE = 'navigation-horizontal--collapse';
exports.LISTER_LAYOUT = '.as-t-lister-layout';
exports.WISHLIST_COMPONENT = '.wishlist-react';
exports.RETURN_ITEMS_COMPONENT = '.return-items';
exports.RETURN_PORTAL_GUEST_FORM_ERROR_FEEDBACK_ID = 'return-portal-guest-login__error-feedback';
exports.LOGIN_FORM_ERROR_FEEDBACK_ID = 'login-form__error-feedback';
exports.FORGOT_PASSWORD_SUCCESS_FEEDBACK_ID = 'forgot-password__success-feedback';
exports.AUTH_POPUP_BUTTON_ID = 'auth-popup-button';
