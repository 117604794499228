"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceChangeHandler = void 0;
const tslib_1 = require("tslib");
const react_1 = require("react");
const formik_1 = require("formik");
const react_i18next_1 = require("react-i18next");
const jsvat_1 = require("jsvat");
const common_components_1 = require("@as-react/common-components");
const GeneralAPI_1 = require("../../api/general/GeneralAPI");
const i18nConstants_1 = require("../../constants/i18nConstants");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const common_types_1 = require("@as-react/common-types");
const InvoiceChangeHandler = ({ countryCode, setCompanyNameLocked, setIsAddressRequired, needInvoiceFieldName = 'invoice', vatNumberFieldName = 'vatNumber', companyFieldName = 'companyName', isVatFieldName = 'finance.isVat', isViesVerifiedFieldName = 'finance.isViesVerified', }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const servicesEndpoint = (0, ConfigurationUtil_1.getServicesEndpoint)(aemConfiguration);
    const defaultRequestParameters = (0, ConfigurationUtil_1.getDefaultRequestParameters)(aemConfiguration);
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const { values, setFieldValue, setFieldError } = (0, formik_1.useFormikContext)();
    const vatNumber = (0, formik_1.getIn)(values, vatNumberFieldName);
    const companyName = (0, formik_1.getIn)(values, companyFieldName);
    const invoice = (0, formik_1.getIn)(values, needInvoiceFieldName);
    const getCompanyDetailsForVatNumber = (vatCountryCode, vatDigits) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
        if (!companyName) {
            const data = yield GeneralAPI_1.default.getCompanyInformationByVAT(servicesEndpoint, defaultRequestParameters, vatCountryCode, vatDigits);
            if (data === null || data === void 0 ? void 0 : data.isVat) {
                setFieldValue(companyFieldName, data.viesResponse.name);
                setFieldValue(isVatFieldName, data.isVat);
                setFieldValue(isViesVerifiedFieldName, data.isViesVerified);
            }
        }
        setCompanyNameLocked(false);
    });
    (0, react_1.useEffect)(() => {
        let isSubscribed = true;
        const validateVat = () => {
            var _a, _b;
            isSubscribed && setCompanyNameLocked(true);
            const currentCountry = countryCode && jsvat_1.countries.find(item => item.codes.includes(countryCode));
            if (!currentCountry) {
                return;
            }
            const vatCheckResult = (0, jsvat_1.checkVAT)(vatNumber, [currentCountry]);
            if (vatCheckResult.isValid) {
                isSubscribed && setFieldValue(vatNumberFieldName, vatCheckResult.value);
                const vatCountryCode = (_a = vatCheckResult.value) === null || _a === void 0 ? void 0 : _a.slice(0, 2);
                const vatDigits = (_b = vatCheckResult.value) === null || _b === void 0 ? void 0 : _b.slice(2);
                vatCountryCode && vatDigits && getCompanyDetailsForVatNumber(vatCountryCode, vatDigits);
            }
            else {
                setCompanyNameLocked(false);
                setTimeout(() => {
                    isSubscribed && setFieldError(vatNumberFieldName, t(common_types_1.ErrorMessage.INVALID_VAT_NUMBER));
                }, GeneralConstants_1.DELAY.DELAY_0);
            }
        };
        if (vatNumber && countryCode) {
            validateVat();
        }
        return () => {
            isSubscribed = false;
        };
    }, [countryCode, vatNumber]);
    (0, react_1.useEffect)(() => {
        setIsAddressRequired(invoice);
    }, [invoice]);
    return null;
};
exports.InvoiceChangeHandler = InvoiceChangeHandler;
