"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const AuthActions_creators_1 = require("../../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../../redux/authentication/selectors/AuthSelectors");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const CheckoutActions_creators_1 = require("../../redux/checkout/actions/CheckoutActions.creators");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const ScrollUtil_1 = require("../../util/ScrollUtil");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const URLParamUtil_1 = require("../../util/URLParamUtil");
const ShopLoginForm_1 = require("../../components/buy/login/ShopLoginForm");
const DOMElementsConstants_1 = require("../../constants/DOMElementsConstants");
const LoginRegistrationForm_1 = require("../../components/account/registration/LoginRegistrationForm");
const BuyLoginForm_1 = require("../../components/buy/login/BuyLoginForm");
const BuyGuestLoginForm_1 = require("../../components/buy/login/BuyGuestLoginForm");
const BasketActions_creators_1 = require("../../redux/basket/actions/BasketActions.creators");
const BuyLoginContainer = ({ actionUri, enableLoginForm, loginFormProps, history, }) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const root = (0, ConfigurationUtil_1.getRoot)(aemConfiguration);
    const inAuthorMode = (0, ConfigurationUtil_1.getInAuthorMode)(aemConfiguration);
    const isShoplanding = (0, ConfigurationUtil_1.getShoplandingEnabledFlag)(aemConfiguration);
    const dispatch = (0, react_redux_1.useDispatch)();
    const loginError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginError)());
    const fastCheckout = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetFastCheckoutState)());
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const resetPasswordState = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetResetPasswordState)());
    const guestError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetErrorGuestState)());
    const loading = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginLoading)());
    const loadingGuest = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginGuestLoading)());
    const { t } = (0, react_i18next_1.useTranslation)([
        i18nConstants_1.I18N_NAMESPACES.account,
        i18nConstants_1.I18N_NAMESPACES.navigation,
        i18nConstants_1.I18N_NAMESPACES.buy,
        i18nConstants_1.I18N_NAMESPACES.checkout,
        i18nConstants_1.I18N_NAMESPACES.general,
    ]);
    const handleShopLandingSubmit = (linkedEmail, cardNumber, uuid) => dispatch(AuthActions_creators_1.authActions.createLoginGuestAction(linkedEmail, cardNumber, uuid));
    const handleLoginRegistrationSuccess = (registrationEmail) => {
        history.push((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.REGISTRATION}`, inAuthorMode, undefined, {
            email: registrationEmail,
        }));
    };
    (0, react_1.useEffect)(() => {
        dispatch(BasketActions_creators_1.basketActions.createGetAction());
        dispatch(AuthActions_creators_1.authActions.createInitSocialAction());
        dispatch(AnalyticsActions_creators_1.analyticsActions.checkoutStep({ step: 2 }));
        dispatch(CheckoutActions_creators_1.default.createLoginAnalyticsAction());
        dispatch(CheckoutActions_creators_1.default.createSetHasRegistrationAction(false));
    }, []);
    (0, react_1.useEffect)(() => {
        if (fastCheckout) {
            history.replace((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.FAST_CHECKOUT}`, inAuthorMode));
        }
        else if (loggedIn) {
            history.replace((0, URLParamUtil_1.generatePathUrl)(root, `buy.html/${common_types_1.RoutePathname.DELIVERY}`, inAuthorMode));
        }
    }, [loggedIn, fastCheckout]);
    (0, react_1.useEffect)(() => {
        if (guestError && !loadingGuest) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById('login-guest-form__error-feedback'));
        }
    }, [guestError, loadingGuest]);
    (0, react_1.useEffect)(() => {
        if (loginError && !loading) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(DOMElementsConstants_1.LOGIN_FORM_ERROR_FEEDBACK_ID));
        }
    }, [loginError, loading]);
    (0, react_1.useEffect)(() => {
        if (resetPasswordState.success) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(DOMElementsConstants_1.FORGOT_PASSWORD_SUCCESS_FEEDBACK_ID));
        }
    }, [resetPasswordState.success]);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: isShoplanding ? undefined : { spacing: { marginTop: 3, marginBottom: 3 } }, tablet: isShoplanding ? undefined : { spacing: { marginTop: 9, marginBottom: 7 } }, children: isShoplanding ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.CheckoutLayout, { variant: cotopaxi_1.CheckoutLayoutVariant.BUY_LOGIN, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.CheckoutLayoutItem, { variant: cotopaxi_1.CheckoutLayoutItemVariants.SHOP_BUY_LOGIN, order: 0, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { withShadow: !isMobile, mobileBleed: true, mobile: {
                        spacing: {
                            paddingLeft: 2,
                            paddingRight: 2,
                            paddingTop: 3,
                            paddingBottom: 1,
                            marginBottom: 1,
                        },
                        theme: cotopaxi_1.BoxTheme.PRIMARY,
                    }, tablet: {
                        spacing: {
                            paddingLeft: 4,
                            paddingRight: 4,
                            paddingTop: 2,
                            paddingBottom: 3,
                        },
                    }, desktop: {
                        spacing: {
                            paddingLeft: 6,
                            paddingRight: 6,
                            paddingTop: 5,
                            paddingBottom: 4,
                        },
                    }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: t('buy:buy.shop.login.title') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(ShopLoginForm_1.default, { onSubmit: handleShopLandingSubmit, errorFeedback: guestError ? t(guestError) : null }) })] }) }) })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { clear: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: true, desktop: enableLoginForm ? { span: 8, push: 2 } : { span: 6, push: 3 }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { tablet: { spacing: { paddingLeft: 6, paddingRight: 6 } }, desktop: enableLoginForm
                        ? { spacing: { paddingLeft: 6, paddingRight: 6 } }
                        : { spacing: { paddingLeft: 10, paddingRight: 10 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { clear: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: true, tablet: { span: 7 }, desktop: enableLoginForm ? { span: 8 } : undefined, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { withShadow: !isMobile, mobileBleed: true, mobile: {
                                        spacing: {
                                            paddingLeft: 2,
                                            paddingRight: 2,
                                            paddingTop: 3,
                                            paddingBottom: 1,
                                            marginBottom: 4,
                                        },
                                        theme: cotopaxi_1.BoxTheme.PRIMARY,
                                    }, tablet: {
                                        spacing: {
                                            paddingLeft: 4,
                                            paddingRight: 4,
                                            paddingTop: 2,
                                            paddingBottom: 3,
                                            marginRight: 4,
                                            marginBottom: 1,
                                        },
                                    }, desktop: {
                                        spacing: {
                                            paddingLeft: 6,
                                            paddingRight: 6,
                                            paddingTop: 5,
                                            paddingBottom: 4,
                                            marginRight: enableLoginForm ? 5 : 0,
                                            marginBottom: 7,
                                        },
                                    }, children: (0, jsx_runtime_1.jsx)(BuyLoginForm_1.BuyLoginForm, { actionUri: actionUri, enableLoginForm: enableLoginForm }) }) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { noGutterLeft: true, noGutterRight: true, tablet: { span: 5 }, desktop: enableLoginForm ? { span: 4 } : undefined, children: [enableLoginForm && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobileBleed: true, mobile: {
                                            spacing: {
                                                paddingLeft: 2,
                                                paddingRight: 2,
                                            },
                                        }, tablet: {
                                            spacing: {
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                            },
                                        }, children: [(0, jsx_runtime_1.jsx)(LoginRegistrationForm_1.LoginRegistrationForm, Object.assign({}, loginFormProps, { onSuccess: handleLoginRegistrationSuccess, emailValidationErrorMessages: {
                                                    invalidEmail: t(common_types_1.ErrorMessage.INVALID_EMAIL),
                                                    [common_types_1.AuthStatusCode.VALID_ACCOUNT]: t(common_types_1.ErrorMessage.CHECKOUT_REGISTRATION_USER_KNOWN),
                                                    [common_types_1.AuthStatusCode.INVALID_ACCOUNT]: t(common_types_1.ErrorMessage.CHECKOUT_REGISTRATION_USER_KNOWN_SAME_FASCIA_NO_PASSWORD),
                                                    [common_types_1.AuthStatusCode.VALID_ACCOUNT_OTHER_FASCIA]: t(common_types_1.ErrorMessage.CHECKOUT_REGISTRATION_USER_KNOWN_OTHER_FASCIA),
                                                    [common_types_1.AuthStatusCode.INVALID_ACCOUNT_OTHER_FASCIA]: t(common_types_1.ErrorMessage.CHECKOUT_REGISTRATION_USER_KNOWN_OTHER_FASCIA_NO_PASSWORD),
                                                } })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 4, marginBottom: 4 } }, tablet: { spacing: { marginTop: 3, marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}) })] })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobileBleed: true, mobile: {
                                            spacing: {
                                                paddingLeft: 2,
                                                paddingRight: 2,
                                            },
                                        }, tablet: {
                                            spacing: {
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                            },
                                        }, children: (0, jsx_runtime_1.jsx)(BuyGuestLoginForm_1.BuyGuestLoginForm, { enableLoginForm: enableLoginForm }) })] })] }) }) }) })) }));
};
exports.default = BuyLoginContainer;
