"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppliedFiltersMobile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const AppliedFilterItem_1 = require("./AppliedFilterItem");
const react_redux_1 = require("react-redux");
const FilterSelectors_1 = require("../../../redux/filter/selectors/FilterSelectors");
const FilterActions_creators_1 = require("../../../redux/filter/actions/FilterActions.creators");
const andes_react_1 = require("@yonderland/andes-react");
const AppliedFiltersMobile = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.product, i18nConstants_1.I18N_NAMESPACES.general]);
    const dispatch = (0, react_redux_1.useDispatch)();
    const flattenedActiveFilters = (0, react_redux_1.useSelector)((state) => (0, FilterSelectors_1.makeGetFlattenedActiveFiltersState)()(state.filters));
    const getContentFilters = (filters) => filters.map((filter) => (Object.assign(Object.assign({}, filter), { filterOnName: t(filter.filterOnName) })));
    const activeFilters = getContentFilters(flattenedActiveFilters);
    const toggleFilter = (toggledFilter) => dispatch(FilterActions_creators_1.filterActions.updateFilters(toggledFilter));
    const removeAllFilters = () => dispatch(FilterActions_creators_1.filterActions.removeAllFilters());
    const getKey = (activeFilterItem) => activeFilterItem.filterType === common_types_1.RANGE_FILTER
        ? activeFilterItem.filterOn
        : `${activeFilterItem.filterOn}_${activeFilterItem.filterKey}`;
    const getAppliedFiltersTitle = () => (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, children: t('product:product.lister.filter.selected_filters') });
    return !!activeFilters && activeFilters.length > 0 ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { children: [getAppliedFiltersTitle(), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingLeft: 0.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Badge, { color: cotopaxi_1.BadgeColor.SECONDARY, count: activeFilters.length }) })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, onClick: removeAllFilters, size: cotopaxi_1.ButtonSize.SMALL, text: t('product.lister.filter.clear') })] }), (0, jsx_runtime_1.jsx)(andes_react_1.Scrollable, { horizontal: true, gradient: true, scrollbarHidden: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.SMALL, wrap: cotopaxi_1.GroupWrap.NOWRAP, children: activeFilters.map(activeFilterItem => ((0, jsx_runtime_1.jsx)(AppliedFilterItem_1.AppliedFilterItem, { onToggleFilter: toggleFilter, activeFilterItem: activeFilterItem, size: cotopaxi_1.ButtonSize.SMALL }, getKey(activeFilterItem)))) }) })] })) : ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, children: [getAppliedFiltersTitle(), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { paddingBottom: 3.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.SUBTLE, children: t('product:product.lister.filter.no_selected_filters') }) })] }));
};
exports.AppliedFiltersMobile = AppliedFiltersMobile;
