"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailReviewTabContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const useReevoo_1 = require("../../hooks/useReevoo");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductActions_1 = require("../../redux/product/actions/ProductActions");
const ExpertReviews_1 = require("../../components/expert-review/ExpertReviews");
const ProductDetailUserReviews_1 = require("../../components/product-detail/product-detail-tab/ProductDetailUserReviews");
const ConfigurationUtil_1 = require("../../util/ConfigurationUtil");
const i18nConstants_1 = require("../../constants/i18nConstants");
const AddReviewV2Container_1 = require("../AddReviewV2Container");
const ProductDetailThirdPartyReviewsContainer_1 = require("./ProductDetailThirdPartyReviewsContainer");
const DEFAULT_ACTIVE_TAB_INDEX = 0;
const ProductDetailReviewTabContainer = ({ showEndUserReview, showExpertReview, isReevoo, reviewDescription, reviewTitle, reviewTypes, inPopover, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const defaultLanguage = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    const alternateUrl = (0, ConfigurationUtil_1.getAlternateUrl)(aemConfiguration);
    const [activeTab, setActiveTab] = (0, react_1.useState)(DEFAULT_ACTIVE_TAB_INDEX);
    const productReviews = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductReviews)());
    const productReviewsAverage = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductReview)());
    const expertReviews = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetExpertReviews)());
    const productReviewsLang = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductReviewsLang)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const fetchReviewData = (lang) => dispatch(ProductActions_1.productActions.createFetchReviewsData(lang));
    const handleTabsChange = (_, newActiveTab) => {
        setActiveTab(newActiveTab);
    };
    const outerBoxProps = {
        mobile: { spacing: { paddingLeft: inPopover ? 2 : 0, paddingRight: inPopover ? 2 : 0, marginBottom: 2 } },
        tablet: { spacing: { paddingLeft: inPopover ? 4 : 0, paddingRight: inPopover ? 4 : 0, marginBottom: 3 } },
    };
    const userReviewsAmount = (productReviewsAverage === null || productReviewsAverage === void 0 ? void 0 : productReviewsAverage.numberOfScores) || 0;
    const getShowUserReviewsTab = () => {
        if (!showEndUserReview && !reviewTitle) {
            return false;
        }
        if (isReevoo && !userReviewsAmount) {
            return false;
        }
        return true;
    };
    const showExpertReviewsTab = showExpertReview && expertReviews.length > 0;
    const getThirdPartyReviews = () => (0, jsx_runtime_1.jsx)(ProductDetailThirdPartyReviewsContainer_1.ProductDetailThirdPartyReviewsContainer, {});
    const getUserReviews = () => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [reviewTitle && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 2 } }, tablet: { spacing: { paddingLeft: 4, paddingRight: 4, marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(AddReviewV2Container_1.AddReviewV2Container, { title: reviewTitle, description: reviewDescription }) })), showEndUserReview && Boolean(userReviewsAmount) && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, outerBoxProps, { children: (0, jsx_runtime_1.jsx)(ProductDetailUserReviews_1.ProductDetailUserReviews, { productReviews: productReviews, fetchReviewData: fetchReviewData, productReviewsLang: productReviewsLang, defaultLanguage: defaultLanguage, alternateUrl: alternateUrl, hasMultipleLanguages: productReviewsAverage === null || productReviewsAverage === void 0 ? void 0 : productReviewsAverage.hasMultipleLanguages }) })))] }));
    const getExpertReview = () => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, outerBoxProps, { children: (0, jsx_runtime_1.jsx)(ExpertReviews_1.ExpertReviews, { expertReviews: expertReviews }) })));
    const getUserReviewCount = () => {
        if (isReevoo) {
            return userReviewsAmount;
        }
        return showEndUserReview ? userReviewsAmount : 0;
    };
    const reviewTabsMap = {
        [common_types_1.ProductDetailReviewType.USER]: {
            label: `${t('pdp.subtab.userReview')} (${getUserReviewCount()})`,
            getContent: () => (isReevoo ? getThirdPartyReviews() : getUserReviews()),
        },
        [common_types_1.ProductDetailReviewType.EXPERT]: {
            label: `${t('pdp.subtab.expertReview')} (${expertReviews.length})`,
            getContent: () => getExpertReview(),
        },
    };
    (0, useReevoo_1.useReevoo)(!!isReevoo);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: getShowUserReviewsTab() && showExpertReviewsTab ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, outerBoxProps, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Tabs, { activeTab: activeTab, onChange: handleTabsChange, children: reviewTypes.map((reviewType, index) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Tab, { activeTab: activeTab, index: index, label: reviewTabsMap[reviewType.type].label }, reviewType.type))) }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { variant: cotopaxi_1.ScrollableListVariant.SHADOW, children: reviewTypes.map((reviewType, index) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.TabPanel, { activePanel: activeTab, index: index, children: reviewTabsMap[reviewType.type].getContent() }, `${reviewType.type}_content`))) })] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showExpertReviewsTab && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, outerBoxProps, { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: inPopover ? cotopaxi_1.HeadingSize.S : cotopaxi_1.HeadingSize.XXS, children: t('pdp.subtab.expertReview') }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { variant: cotopaxi_1.ScrollableListVariant.SHADOW, children: getExpertReview() })] })), getShowUserReviewsTab() && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({}, outerBoxProps, { children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Heading, { size: inPopover ? cotopaxi_1.HeadingSize.S : cotopaxi_1.HeadingSize.XXS, children: [t('pdp.subtab.userReview'), " (", getUserReviewCount(), ")"] }) })), (0, jsx_runtime_1.jsx)(cotopaxi_1.ScrollableList, { variant: cotopaxi_1.ScrollableListVariant.SHADOW, children: isReevoo ? getThirdPartyReviews() : getUserReviews() })] }))] })) }));
};
exports.ProductDetailReviewTabContainer = ProductDetailReviewTabContainer;
