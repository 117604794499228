"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const AvailabilityDescription_1 = require("../components/product-detail/availability-description/AvailabilityDescription");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const ConfigurationUtil_1 = require("../util/ConfigurationUtil");
const AvailabilityDescriptionContainer = ({ unavailableProduct, hdExcluded, ccExcluded, fulfillmentCopyDescription, fulfillmentBySize: { storeSourceExcluded, fulfillmentCopy }, }) => {
    const aemConfiguration = (0, common_components_1.useAemConfigurationContextWrapper)();
    const lang = (0, ConfigurationUtil_1.getLang)(aemConfiguration);
    return ((0, jsx_runtime_1.jsx)(AvailabilityDescription_1.AvailabilityDescription, { unavailableProduct: unavailableProduct, hdExcluded: hdExcluded, ccExcluded: ccExcluded, storeSourceExcluded: storeSourceExcluded, fulfillmentCopyDescription: storeSourceExcluded ? fulfillmentCopy === null || fulfillmentCopy === void 0 ? void 0 : fulfillmentCopy[lang.toUpperCase()] : fulfillmentCopyDescription }));
};
const makeMapStateToProps = () => {
    const getProductFulfillment = (0, ProductSelector_1.makeGetProductFulfillment)();
    const getProductFulfillmentBySize = (0, ProductSelector_1.makeGetProductFulfillmentBySize)();
    const mapStateToProps = (state) => ({
        hdExcluded: getProductFulfillment(state).hdExcluded,
        ccExcluded: getProductFulfillment(state).ccExcluded,
        fulfillmentCopyDescription: getProductFulfillment(state).fulfillmentCopyDescription,
        fulfillmentBySize: getProductFulfillmentBySize(state),
    });
    return mapStateToProps;
};
exports.default = (0, react_redux_1.connect)(makeMapStateToProps)(AvailabilityDescriptionContainer);
