"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const AuthActions_creators_1 = require("../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../redux/authentication/selectors/AuthSelectors");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const SessionSelector_1 = require("../redux/session/selectors/SessionSelector");
const CookieConsentSelector_1 = require("../redux/cookieConsent/selectors/CookieConsentSelector");
const ReturnPortalGuestLogin_1 = require("../components/account/return-portal-guest-login/ReturnPortalGuestLogin");
const Authentication_1 = require("../components/account/authentication/Authentication");
const ScrollUtil_1 = require("../util/ScrollUtil");
const DOMElementsConstants_1 = require("../constants/DOMElementsConstants");
const LoginRegistrationForm_1 = require("../components/account/registration/LoginRegistrationForm");
const NavigationUtil_1 = require("../util/NavigationUtil");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const AnalyticsEnums_1 = require("../enums/AnalyticsEnums");
const ReturnPortalLoginContainer = ({ returnItemsPath, orderOverviewPath, accountRegistrationPath, enableLoginForm, loginForm: loginFormProps, }) => {
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const loginError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginError)());
    const sessionState = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const loggedIn = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetLoggedInState)());
    const loggedInGuest = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoggedInReturnGuestState)());
    const resetPasswordState = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetResetPasswordState)());
    const guestError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetErrorReturnGuestState)());
    const loading = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginLoading)());
    const loadingGuest = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginReturnGuestLoading)());
    const cookieConsentSettingAvailable = (0, react_redux_1.useSelector)((0, CookieConsentSelector_1.makeGetCookieConsentSettingAvailable)());
    const [shouldRender, setShouldRender] = (0, react_1.useState)(false);
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.navigation, i18nConstants_1.I18N_NAMESPACES.account]);
    (0, react_1.useEffect)(() => {
        dispatch(AuthActions_creators_1.authActions.createInitSocialAction());
    }, []);
    (0, react_1.useEffect)(() => {
        (sessionState.authorities || sessionState.error) && !shouldRender && setShouldRender(true);
    }, [sessionState, shouldRender]);
    (0, react_1.useEffect)(() => {
        if (loggedIn) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.returnPortalLogin(AnalyticsEnums_1.ReturnPortalLoginType.KNOWN_USER));
            window.location.replace((0, NavigationUtil_1.getHomepageUrl)(returnItemsPath));
        }
    }, [loggedIn]);
    (0, react_1.useEffect)(() => {
        if (loggedInGuest && !loggedIn) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.returnPortalLogin(AnalyticsEnums_1.ReturnPortalLoginType.GUEST));
            window.location.replace((0, NavigationUtil_1.getHomepageUrl)(orderOverviewPath));
        }
    }, [loggedInGuest, loggedIn]);
    (0, react_1.useEffect)(() => {
        if (loginError && !loading) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(DOMElementsConstants_1.LOGIN_FORM_ERROR_FEEDBACK_ID));
        }
    }, [loginError, loading]);
    (0, react_1.useEffect)(() => {
        if (guestError && !loadingGuest) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(DOMElementsConstants_1.RETURN_PORTAL_GUEST_FORM_ERROR_FEEDBACK_ID));
        }
    }, [guestError, loadingGuest]);
    if (!shouldRender || loggedIn)
        return null;
    const handleLoginSubmit = (values) => {
        dispatch(AuthActions_creators_1.authActions.login({
            username: values.email,
            password: values.password,
            keepSignedIn: values.keepSignedIn,
            reloadOnSuccess: false,
            location: common_types_1.AuthFormLocation.RETURN_PORTAL,
        }));
    };
    const handleResetPasswordSubmit = (values) => {
        dispatch(AuthActions_creators_1.authActions.createResetCredentialsAction(values.email));
    };
    const handleSubmitGuest = (values) => {
        dispatch(AuthActions_creators_1.authActions.createLoginReturnGuestAction({
            username: values.guestEmail,
            orderNumber: values.ordernumber,
        }));
    };
    const handleLoginRegistrationSuccess = (registrationEmail) => {
        dispatch(AnalyticsActions_creators_1.analyticsActions.returnPortalLogin(AnalyticsEnums_1.ReturnPortalLoginType.KNOWN_USER));
        window.location.href = `${(0, NavigationUtil_1.getHomepageUrl)(accountRegistrationPath)}?${common_types_1.RegistrationSearchParameters.EMAIL}=${registrationEmail}&${common_types_1.RegistrationSearchParameters.CALLBACK_URL}=${encodeURIComponent((0, NavigationUtil_1.getHomepageUrl)(returnItemsPath))}`;
    };
    const renderRegistration = enableLoginForm ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobileBleed: true, mobile: {
            spacing: {
                paddingLeft: 2,
                paddingRight: 2,
            },
        }, tablet: {
            spacing: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        }, children: [(0, jsx_runtime_1.jsx)(LoginRegistrationForm_1.LoginRegistrationForm, Object.assign({}, loginFormProps, { onSuccess: handleLoginRegistrationSuccess, emailValidationErrorMessages: {
                    invalidEmail: t(common_types_1.ErrorMessage.INVALID_EMAIL),
                    [common_types_1.AuthStatusCode.VALID_ACCOUNT]: t(common_types_1.ErrorMessage.RETURN_PORTAL_REGISTRATION_USER_KNOWN),
                    [common_types_1.AuthStatusCode.INVALID_ACCOUNT]: t(common_types_1.ErrorMessage.RETURN_PORTAL_REGISTRATION_USER_KNOWN_SAME_FASCIA_NO_PASSWORD),
                    [common_types_1.AuthStatusCode.VALID_ACCOUNT_OTHER_FASCIA]: t(common_types_1.ErrorMessage.RETURN_PORTAL_REGISTRATION_USER_KNOWN_OTHER_FASCIA),
                    [common_types_1.AuthStatusCode.INVALID_ACCOUNT_OTHER_FASCIA]: t(common_types_1.ErrorMessage.RETURN_PORTAL_REGISTRATION_USER_KNOWN_OTHER_FASCIA_NO_PASSWORD),
                } })), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 4, marginBottom: 4 } }, tablet: { spacing: { marginTop: 3, marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}) })] })) : null;
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3, marginBottom: 3 } }, tablet: { spacing: { marginTop: 9, marginBottom: 7 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { desktop: { spacing: { marginBottom: 4, marginLeft: 6 } }, tablet: { spacing: { marginBottom: 3.5, marginLeft: 6 } }, mobile: { spacing: { marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.EXTRA_TITLE, alignment: cotopaxi_1.HeadingAlignment.LEFT, children: t('account:account.return.login.title') }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Container, { type: isMobile ? cotopaxi_1.ContainerType.FULL_WIDTH : cotopaxi_1.ContainerType.FLUID, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, mobileLarge: 12, tabletSmall: 12, grid: 0, tablet: 6, desktop: 8, gutter: isMobile, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                    spacing: {
                                        marginRight: 0,
                                        marginBottom: 3,
                                    },
                                }, tablet: {
                                    spacing: {
                                        marginRight: 1,
                                        marginLeft: 6,
                                    },
                                }, desktop: {
                                    spacing: {
                                        marginRight: 6,
                                    },
                                }, children: (0, jsx_runtime_1.jsx)(Authentication_1.Authentication, { login: {
                                        onSubmit: handleLoginSubmit,
                                        errorFeedback: loginError,
                                        loading,
                                    }, forgotPassword: {
                                        onSubmit: handleResetPasswordSubmit,
                                        errorFeedback: resetPasswordState.error,
                                        successFeedback: resetPasswordState.success,
                                    }, facebook: {}, cookieConsentSettingAvailable: cookieConsentSettingAvailable === common_types_1.CookieConsentSettingState.CONSENTS_AVAILABLE, socialLogin: payload => dispatch(AuthActions_creators_1.authActions.createSocialLoginAction(payload)), onAuthResetForm: () => dispatch(AuthActions_creators_1.authActions.resetError()) }) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { mobile: 12, mobileLarge: 12, tabletSmall: 12, grid: 0, tablet: 6, desktop: 4, gutter: isMobile, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobileBleed: true, mobile: {
                                    spacing: {
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        paddingTop: 1,
                                    },
                                }, tablet: {
                                    spacing: {
                                        paddingLeft: 4,
                                        paddingRight: 8,
                                    },
                                }, children: [renderRegistration, (0, jsx_runtime_1.jsx)(ReturnPortalGuestLogin_1.ReturnPortalGuestLogin, { onSubmit: handleSubmitGuest, errorFeedback: guestError ? t(guestError) : null, loading: loadingGuest }), isMobile && (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {})] }) })] }) })] }));
};
exports.default = ReturnPortalLoginContainer;
