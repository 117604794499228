"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const ScrollUtil_1 = require("../../../../util/ScrollUtil");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const DOMElementsConstants_1 = require("../../../../constants/DOMElementsConstants");
const GeneralConstants_1 = require("../../../../constants/GeneralConstants");
const InStoreFilterToast = ({ inStoreFilter, activeStoreId, updateStoreId, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const [showToast, setShowToast] = (0, react_1.useState)(false);
    const handleClose = () => {
        setShowToast(false);
    };
    (0, react_1.useEffect)(() => {
        if (!inStoreFilter) {
            return undefined;
        }
        const timer = setTimeout(() => setShowToast(true), GeneralConstants_1.DELAY.DELAY_15000);
        return () => clearTimeout(timer);
    }, [inStoreFilter]);
    if (!inStoreFilter) {
        return null;
    }
    const handleFilterChange = () => {
        updateStoreId(inStoreFilter.storeId);
        ScrollUtil_1.ScrollUtil.scrollToElement(document.querySelector(DOMElementsConstants_1.LISTER_LAYOUT));
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Toast, { open: showToast, onClose: handleClose, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, align: !isDesktop ? cotopaxi_1.GroupAlign.SPACE_BETWEEN : cotopaxi_1.GroupAlign.LEFT, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { grow: 1 }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, isClipped: isDesktop, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.STORE, size: cotopaxi_1.IconSize.MEDIUM, inline: true }), " ", t('product.lister.inStore.visibleFrom'), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, inline: isDesktop, isClipped: !isDesktop, size: cotopaxi_1.TextSize.INHERIT, children: inStoreFilter.storeName })] }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { shrink: 0 }, mobile: {
                        spacing: {
                            marginLeft: 2,
                            marginRight: isMobile ? 0.25 : 3,
                        },
                    }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Switch, { checked: !!activeStoreId, id: "in_store_filter_toast", onChange: handleFilterChange }) })] }) }));
};
exports.default = InStoreFilterToast;
