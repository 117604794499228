"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterItemContentRangeFilter = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const GeneralUtil_1 = require("../../../util/GeneralUtil");
const common_utils_1 = require("@as-react/common-utils");
const FilterItemContentRangeFilterInputs_1 = require("./FilterItemContentRangeFilterInputs");
const FilterItemContentRangeFilter = ({ filter: { rangeMin, rangeMax, name, filterOn, filterPrefix, filterSuffix, boundaryMin = rangeMin, boundaryMax = rangeMax, filterContentType, }, isActive, onFilterItemChange, }) => {
    const [value, setValue] = (0, react_1.useState)({
        min: Math.floor(rangeMin),
        max: Math.ceil(rangeMax),
    });
    const [displayValue, setDisplayValue] = (0, react_1.useState)(value);
    const isValueValid = !((0, common_utils_1.isNullOrUndefined)(value.max) || (0, common_utils_1.isNullOrUndefined)(value.min));
    // if there is an undefined property value in value, default to the previous legal one
    const sliderValue = (isValueValid && value) || displayValue;
    (0, react_1.useEffect)(() => {
        // Only set value when filter is not active
        if (!isActive) {
            setValue({
                min: Math.floor(rangeMin),
                max: Math.ceil(rangeMax),
            });
        }
    }, [rangeMin, rangeMax]);
    const handleChange = (v, finished) => {
        setValue(v);
        if (finished && !(0, GeneralUtil_1.isEqual)(value, displayValue)) {
            if (!isValueValid) {
                setValue(displayValue);
                return;
            }
            onFilterItemChange({
                filterOn: filterOn,
                filterOnName: name,
                filteredItems: [{ filterKey: v.min.toString() }, { filterKey: v.max.toString() }],
                filterType: common_types_1.RANGE_FILTER,
                filterPrefix: filterPrefix,
                filterSuffix: filterSuffix,
                filterContentType: filterContentType,
            });
            setDisplayValue(value);
        }
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.RangeSlider, { id: filterOn, name: filterOn, value: sliderValue, onChange: handleChange, min: boundaryMin, max: boundaryMax }), (0, jsx_runtime_1.jsx)(FilterItemContentRangeFilterInputs_1.FilterItemContentRangeFilterInputs, { value: value, onChange: handleChange, min: boundaryMin, max: boundaryMax, filterPrefix: filterPrefix, filterSuffix: filterSuffix })] }));
};
exports.FilterItemContentRangeFilter = FilterItemContentRangeFilter;
